
import { Component, ElementRef, HostListener, inject, ViewChild, AfterViewInit, Renderer2, ViewChildren, QueryList } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CustomDatePipe } from './../pipes/customdate.pipe';
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from 'ngx-bootstrap/datepicker';
import { DecimalPipe, formatNumber } from '@angular/common';
import { SignupformService } from './../services/signupform.service';
import { Meta } from '@angular/platform-browser';
import { environment } from './../environments/environment'
import { SignaturePad } from 'angular2-signaturepad';
import { PhoneNumberPipe } from './../pipes/phone-number.pipe'
import { SharedService } from '../shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrl: './payment-method.component.scss',
  providers: [CustomDatePipe, DecimalPipe, HttpClient, PhoneNumberPipe],
})
export class PaymentMethodComponent implements AfterViewInit {
  @ViewChild('myTextarea') myTextarea!: ElementRef<HTMLTextAreaElement>;

  myDateValue: Date | undefined;
  Object = Object;
  date: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  // bsConfig: any;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  childminDate: Date = new Date();
  childmaxDate: Date = new Date();
  showBenificiaryForm = true;
  showbenificaryTable = false;
  isEditable = true;
  enableEditIndex = null;
  addeditbenficiary = false
  benficiaryCount = false;

  paymentForm = new FormGroup(
    {
      cutomerId: new FormControl(''),
      PublicApiKey: new FormControl(''),
      SuccessUri: new FormControl(''),
      FailUri: new FormControl(''),
      FirstName: new FormControl(''),
      LastName: new FormControl(''),
      paymentRadioCheck: new FormControl(''),
      email: new FormControl(''),
      CardNumber: new FormControl(''),
      ExpirationMonth: new FormControl(''),
      ExporationYear: new FormControl(''),
      cvv: new FormControl(''),

      // signature:["", Validators.required],
      nameonthecard: new FormControl(''),

      // aptcheck: ["", Validators.compose([apt_suitecheck])],
      aptcheck: new FormControl(''),

      streetaddress: new FormControl(''),
      streetaddressline2: new FormControl(''),
      city: new FormControl(''),
      province: new FormControl(''),
      postalcode: new FormControl(''),
      recaptchaReactive: new FormControl(''),
    },
    { updateOn: 'change' }
  );
  bankpayForm = new FormGroup(
    {
      banknumber: new FormControl(''),

      transitnumber: new FormControl(''),
      accountnumber: new FormControl(''),
      bankname: new FormControl(''),
      voidcheck: new FormControl(''),
      checkbankdetails: new FormControl(''),
      bankaptcheck: new FormControl(''),
      bankstreetaddress: new FormControl(''),
      bankstreetaddressline2: new FormControl(''),
      bankcity: new FormControl(''),
      bankprovince: new FormControl(''),
      bankpostalcode: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      streetaddress: new FormControl(''),
      streetaddressline2: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl(''),
      province: new FormControl(''),
      postalcode: new FormControl(''),
      apt: new FormControl(''),
      primaryPhone: new FormControl(''),
      primaryEmail: new FormControl('')
    },
    { updateOn: 'change' }
  );

  submitted = false;
  private http = inject(HttpClient);
  public languageTokensArray: any = [];
  public lang: any = {};
  public selectedLanguage: any;
  public configprovinceres: any;
  activeTab: any = 0;
  previousTab: any = -1;
  bsValue = new Date();
  invalidPostalCodeForProvince: boolean = false;
  provineList: any;
  provineListPayment: any;
  provineListcreditPayment: any
  plansData: any = [{ groups: [{ plans: [] }] }];
  configplanEnrollmentDateFull: any;
  childInfoAddDataArray: any = [];
  addbenificaryData: any = []
  childTableEditRowIndex: number = 0;
  public staticData: any = [];
  public ticks: any[] | undefined;
  public cardBackground: any = [];
  public cardColors: any = [];

  public paymentUri: any;
  public paymentsuccessuri: any;
  public customerid: any;
  public publicapikey: any = '440a0e0d-a6f4-4955-a8b6-3ac7f837ddb4';
  public cardType: string = '';
  public invalidcreditcard: boolean = false;
  public recaptchaResponse: any;
  public fusbillinfocaptchavalue: any;
  childTableRowsLength: number = 0;
  childInfoModalAddAndUpdateButton: string = '';
  BenficairyModalAddAndUpdateButton: string = '';
  noOfChildrenChangedValue: any = 1;
  showModal = false;
  showDeleteModal = false;
  showModelDialog = false;
  ShowModelPopup = false;
  showPADModelDiaslog = false;
  modalMessage = 'Do you want to proceed?';
  deleteChildIndex: any;
  editChildInfo: any;
  editChildIndex: any;
  editBenficiaryIndex: any;
  spouseplaceholdername: any;
  checkedradioValue: boolean = false;
  pdfData: any;
  formConfigResult: any;
  public disabledelement: any;
  planobjdata: any = {};
  public planAmount: any;
  // planobjdata: { packageId: any; packageName: any; planproductname: any; groupid: any; groupName: any; id: any; name: any; planLevel: any; planLevelParent: any; fusebillPlanID: any; planFrequencyID: any; isBundle: any; coverage: any; planCoverage: any; bundledProducts: never[]; products: never[]; };
  productobj: any;
  public planssummarymain: any = [];
  public planssummarymainLife: any = [];
  public planssummaryopt: any = [];
  public plansskumain: any = [];
  public plandetailsobjvalue: any;
  public optionstitle: any = [];
  paymentForm2: any;
  finalenrollemntsummary: any = [];
  finalenrollemntsummaryLife: any = [];
  planSummaryMainResult: any = [];
  banknameres: any;
  bankerrormsg: any;
  public verifybutton: boolean = false;
  public verifybankdetails: boolean = false;
  bankverifyDetails: any;
  bankfile: any;
  isFileUploaded: boolean = false;
  bankinvalidPostalCode: boolean = false;
  pdfscrc: any = 'https://api.groupbenefitz.ca/app/server/en/terms-conditions-en.pdf'
  header: any;
  failedarray: any = [];
  signatureNeeded: any;
  creditCradInvalidProvince: any;
  showClearButton: boolean = false;
  checked: { [userId: string]: boolean } = {};
  missingInfo: any = [];
  missingInformation = false;
  planOptionsModel = false;
  optionmessage: any;
  planoptionsValues: any = [];
  link: any;
  generatePADaggrement: any;
  padAggrementModel = false;
  padAggrementFinalModel = false;
  public signaturestatus: boolean = true;
  public rejectPADModel: boolean = true;
  public agreestatus: boolean = false;
  signModelForBankPay = false;
  closePADModel = false;
  signaturecheckvalue1cc: boolean = false;
  public signaturemessagecc: boolean = false;
  public signaturecheckcc: boolean = true;
  paymentemail: any;
  public signaturecheckvalue1: boolean = false;
  public signaturemessagepad: boolean = false;
  public acceptaggrepad: boolean = true;
  public padaggrementerror: any;
  public signaturecheck: boolean = true;
  public signaturemessage: boolean = false;
  imagedisplay: any;
  showpaderrorMessage: boolean = false;
  showagree: boolean = true;
  ShowModelPopupTitle: any;
  ShowModelPopupMessage: any;
  invalidCardDetails: boolean = false;
  public showexpiryyearerror: boolean = false;
  public showexpirymontherror: boolean = false;
  public futureexpiredate: boolean = false;
  visitedTabs: Set<string> = new Set<string>();
  imageDisplayShow = false;
  questionaries = false;
  benficiary = false
  public normalview: boolean = false;
  public pdfview: boolean = false;
  applicantSpousename: any;
  applicantfirstname: any;
  applicantChildrenname: any;
  completeapplicantinfo: any;

  public formData: any;
  public allFormData: any = {};
  public lastUpdatedType: any = "";
  public signUpData: any = {};
  public premiums: any = {};
  planCoverageName: any;
  insuranceplanid: any;
  smokerCheckValue: any;
  acceptanceCoverage: any;
  paymentFirstName: any;
  paymentLastName: any;

  tableForm!: FormGroup;
  beneficariesDetails: any;
  showaddmorebenficiary = true;
  benficiaryCountMessage: any;
  beneficiaryError = false;
  beneficiaryErrorMessage = ''
  iafgCheck: any;
  iafginfo = false;
  memberDateofBirth = false;
  minValue = 0;
  maxValue: any;
  sliderValue: any;
  cutoffValue: any;
  units: any = [];
  isCorporate: boolean = false;
  corporateSubDomain: any;

  greenWidth: any;
  orangeWidth: any;
  filledWidth: any;
  cutoffWidth: any;
  rangeValues: any = [];
  names: any[] = [
    { label: 'Guaranteed', position: 10 },    // Display "Start" at 0%
    { label: 'Pending', position: 0 }, // Display "Quarter" at 25%

  ];
  greenValue: any;
  yellowValue: any;
  languageData: any;
  CreditCradFirstName: any;
  CreditCradLastName: any;
  constructor(
    private Shared: SharedService,
    private _SignupformService: SignupformService,
    private formBuilder: FormBuilder,
    public elementRef: ElementRef,
    private _decimalPipe: DecimalPipe,
    public router: Router,
    public route: ActivatedRoute,
    public toastrService: ToastrService
  ) {

    this.minDate = new Date();
    const today = new Date();
    this.minDate.setFullYear(today.getFullYear() - 74);
    this.minDate.setMonth(0); // January
    this.minDate.setDate(1); // 1st

    // Set maxDate to December 31st, 2030
    this.maxDate = new Date();
    this.maxDate.setFullYear(today.getFullYear() - 16);
    this.maxDate.setMonth(new Date().getMonth()); // current month
    this.maxDate.setDate(new Date().getDate()); // current date

    this.childminDate = new Date();
    this.childminDate.setFullYear(today.getFullYear() - 21);
    this.childminDate.setMonth(0); // January
    this.childminDate.setDate(1); // 1st

    // Set maxDate to December 31st, 2030
    this.childmaxDate = new Date();
    this.childmaxDate.setFullYear(today.getFullYear());
    this.childmaxDate.setMonth(new Date().getMonth()); // current month
    this.childmaxDate.setDate(new Date().getDate()); // current date

    this.bsConfig = {
      containerClass: 'theme-default',
      dateInputFormat: 'MM-DD-YYYY',
      minDate: this.minDate,
      maxDate: this.maxDate,
    };
    this.tableForm = this.formBuilder.group({
      rows: this.formBuilder.array([])
    });

    this.paymentForm = this.formBuilder.group({
      cutomerId: [''],
      PublicApiKey: [''],
      SuccessUri: [''],
      FailUri: [''],
      FirstName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      LastName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      email: [''],
      paymentRadioCheck: ['', Validators.required],
      CardNumber: [
        '',
        Validators.compose([Validators.required, creditcardvalidation]),
      ],
      ExpirationMonth: ['', Validators.required],
      ExporationYear: ['', Validators.required],
      cvv: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      nameonthecard: [''],
      aptcheck: [''],
      streetaddress: [''],
      streetaddressline2: [''],
      city: [''],
      province: [''],
      postalcode: [''],
      recaptchaReactive: ['', Validators.required],
    });
    this.bankpayForm = this.formBuilder.group({
      banknumber: [
        '',
        Validators.compose([Validators.required, checkbanknumber]),
      ],

      transitnumber: [
        '',
        Validators.compose([Validators.required, checktransitnumber]),
      ],
      accountnumber: [
        '',
        Validators.compose([Validators.required, checkaccountnumber]),
      ],
      bankname: ['', Validators.required],
      voidcheck: ['', Validators.compose([Validators.required])],
      checkbankdetails: [''],
      // bankaptcheck: ["", Validators.compose([apt_suitecheck])],
      bankaptcheck: [''],
      bankstreetaddress: ['', Validators.required],
      bankstreetaddressline2: [''],
      bankcity: ['', Validators.required],
      bankprovince: ['', Validators.required],
      bankpostalcode: ['', Validators.compose([Validators.required])],
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      streetaddress: ['', Validators.required],
      streetaddressline2: [''],
      city: ['', Validators.required],
      country: ['', Validators.required],
      province: ['', Validators.required],
      postalcode: ['', Validators.required],
      primaryPhone: ['', Validators.required],
      primaryEmail: ['', Validators.required],
      apt: [''],
    });
  }

  @ViewChild(SignaturePad) signaturePad!: SignaturePad;

  // @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
  //   e.preventDefault();
  // }
  signaturePadOptions: Object = {


    'canvasWidth': 400,
    'canvasHeight': 150
  };
  public cartcheckvalue: boolean = true;
  public plansnexttab: boolean = true;

  // @ViewChild('sigPad')
  // sigPad!: ElementRef<HTMLCanvasElement>;
  // @ViewChild('sigPad') sigPad;
  sigPadElement: any;
  context: any;
  isDrawing = false;
  img: any;
  // @ViewChild('sigPad', { static: false }) sigPad!: ElementRef<HTMLCanvasElement>;

  @ViewChild('canvasElement', { static: false }) canvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasElementpad', { static: false }) canvaspad!: ElementRef<HTMLCanvasElement>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('scrollContainerPlans') scrollContainerPlans!: ElementRef;
  @ViewChild('optionNumberInput') optionNumberInput!: ElementRef;
  @ViewChild('optionPlanDetailsObj') optionPlanDetailsObj!: ElementRef;
  @ViewChild('planCheckdetails') planCheckdetails!: ElementRef;
  @ViewChildren('optionUnseelct') checkboxes!: QueryList<ElementRef>;


  private ctx: CanvasRenderingContext2D | null = null;
  private ctxpad: CanvasRenderingContext2D | null = null;
  private resizeObserver: ResizeObserver | null = null;
  private drawing = false;
  sigPadElementpad: any;
  contextpad: any;
  isDrawingpad = false;

  @ViewChild('sigPad') sigPad: any;
  coverageValue: any;
  minimumCoverage: any; // Set minimum coverage threshold
  extraCoverage: any;
  extraCoverage1: any;
  showSpouseSignature = false;
  showIAFGerrorMessage = false;
  ConfirmModel = false;
  showyellowbar = false
  criticalplancheck = false
  termLifeplancheck = false

  token: any;
  paymentMethod: any = '';
  details: any;
  countriesList: any = [];

  ngOnInit(): void {

    // setTimeout(() => this.initializeCanvas(), 20000);

    this.getLanguageTokens()
    // this.updateSlider()

    sessionStorage.setItem("timeStamp", new Date().getTime().toString())

    this.getIPAddress()
    // this.postAnalyticsData()
    // this.commonSettings()
    // this.getUserDetails()

    setInterval(() => {

      // this.postAnalyticsData()
    }, 30000);

    sessionStorage.setItem("lanToken", 'en')

    sessionStorage.removeItem("fileurl")
    sessionStorage.removeItem("filename")
    sessionStorage.removeItem("signature")

    if (sessionStorage.getItem("formlink")) {
      this.link = sessionStorage.getItem("formlink")
    } else {
      this.link = sessionStorage.getItem("formlink")
    }

    if (sessionStorage.getItem("paymentMethod")) {
    } else {
      sessionStorage.setItem("paymentMethod", "CC");
    }

    this.customerid = sessionStorage.getItem("customerId");
    // this.publicapikey = sessionStorage.getItem("publicApiKey");
    // this.testFun();

    let form = 'SHZMOxgC0301';

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.paymentMethod = params['method'];
      const success = params['success'];
      const paymentMethodId = params['paymentMethodId'];
      console.log({ success, paymentMethodId })

      const baseUrl = window.location.origin;
      this.paymentUri = baseUrl + "/update?token=" + this.token + "&method=" + this.paymentMethod;
      this.paymentsuccessuri = baseUrl + "/update?token=" + this.token + "&method=" + this.paymentMethod;

      if (success != undefined && success == 'True') {
        this.token = this.token.split('').reverse().join('');
        this.token = window.atob(this.token);
        this.customerid = this.token.split('-')[0]
        const inputData2 = {
          paymentMethod: 'CC',
          igbmsId: this.customerid,
          paymentMethodId
        }
        this._SignupformService.updatepayment(inputData2).subscribe((response: any) => {
          if (response.status == 200) {
            Swal.fire({ title: 'Info', text: 'Credit Card Details verfied successfully' }).then(() => {
              if(this.isCorporate){
                // Navigate to corporate portal after the alert is closed
                window.location.href = `https://${this.corporateSubDomain}.testcorporate.groupbenefitz.aitestpro.com/login`;
                // this.paymentForm.reset()
                // this.bankpayForm.reset()
              }else{
                // Navigate to Member portal after the alert is closed
                window.location.href = environment.app.memberPortal + 'login';
              }
            });
          } else {
            Swal.fire({ title: 'Error', text: response.error.message }).then(() => { });
          }
        })
        //   // Navigate to Member portal after the alert is closed
        //   window.location.href = environment.app.memberPortal + 'login';
        // });
        return;
      }

      if (success != undefined && success == 'False') {
        Swal.fire({ title: 'Error', text: 'Invalid Credit Card Details, Please try again' }).then(() => {
          // Navigate to payment method after the alert is closed
          console.log({ baseUrl })
          const newUrl = '/update?token=' + encodeURIComponent(this.token) + '&method=' + encodeURIComponent(this.paymentMethod);
          // Use router to navigate to the new URL
          window.location.href = baseUrl + newUrl;
        });
        return;
      }

      this.fusbillinfocaptchavalue = '6LfVtGwUAAAAALHn9Ycaig9801f6lrPmouzuKF11';
      this.token = this.token.split('').reverse().join('');
      this.token = window.atob(this.token);

      // if paymentMethod = CC
      if (this.paymentMethod && this.paymentMethod == 'CC') {
        this.paymentForm.get('paymentRadioCheck')?.setValue('CC');
        // if paymentMethod = PAD
      } else if (this.paymentMethod && this.paymentMethod == 'PAD') {
        this.paymentForm.get('paymentRadioCheck')?.setValue('PAD');
        // if paymentMethod != CC and PAD
      } else {
        this.paymentForm.get('paymentRadioCheck')?.setValue('');
        this.paymentMethod = ''
      }
      console.log('Query parameter value:', this.token, this.paymentMethod);
    });
    this.customerid = this.token.split('-')[0]
    console.log('this.customerid', this.customerid)
    this.getDetails()

  }

  public getDetails() {
    this.http.get(environment.app.grp + 'api/common/customer/' + this.customerid + '/primeDetails').subscribe((response: any) => {

      // get basic information from response
      const responseData = response?.data
      this.countriesList = responseData?.countries;
      this.paymentForm.get('FirstName')?.setValue(responseData?.firstName)
      this.paymentForm.get('LastName')?.setValue(responseData?.lastName)
      this.bankpayForm.get('firstName')?.setValue(responseData?.firstName)
      this.bankpayForm.get('lastName')?.setValue(responseData?.lastName)
      
      //get address from repsonse
      const responseAddress = responseData?.address;
      this.bankpayForm.get('streetaddress')?.setValue(responseAddress?.line1)
      this.bankpayForm.get('streetaddressline2')?.setValue(responseAddress?.line2 ?? '')
      this.bankpayForm.get('city')?.setValue(responseAddress?.city)
      this.bankpayForm.get('country')?.setValue(responseAddress?.country)
      this.changeCountry({ target: { value: responseAddress?.country } })
      this.bankpayForm.get('province')?.setValue(responseAddress?.state)
      this.bankpayForm.get('postalcode')?.setValue(responseAddress?.postalCode)
      this.bankpayForm.get('apt')?.setValue(responseAddress?.apt)
      this.bankpayForm.get('primaryPhone')?.setValue(responseAddress?.primaryPhone)
      this.bankpayForm.get('primaryEmail')?.setValue(responseAddress?.primaryEmail)

      // get portal
      this.isCorporate = response.data?.isCorporate
      this.corporateSubDomain = response.data?.portal
    })
  }

  public changeCountry(event: any) {
    this.configprovinceres = this.countriesList.
      filter((country: any) => country.name == event.target.value)[0]?.statesAndProvinces
    console.log('this.configprovinceres', this.configprovinceres)
  }

  public getLanguageTokens() {
    let body = {
      "project": "SignupForm",
      "url": "GIG"
    }
    this.http
      .post('https://languageengineapi.aitestpro.com/string/project-url-strings', body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .subscribe(
        (response: any) => {
          console.log(response)
          this.lang = {};
          this.languageData = response.strings
          this.selectedLanguage = sessionStorage.getItem("language")
          for (let token of response.strings) {
            this.lang[token.key] = token[this.selectedLanguage];
          }
        })
  }


  updateSlider() {
    const value = this.sliderValue;
    this.greenWidth = (this.cutoffValue / this.maxValue) * 100;
    this.orangeWidth = 100 - this.greenWidth;
    this.filledWidth = (value / this.maxValue) * 100;
    this.cutoffWidth = this.greenWidth;
  }

  public genRandonString(length: any) {
    var chars = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charLength = chars.length;
    var result = "";
    for (var i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
  }
  ngAfterViewInit(): void {

  }
  setupResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(() => this.resizeCanvas());
    this.resizeObserver.observe(this.canvas.nativeElement);
  }
  postAnalyticsData() {

    var pageName = sessionStorage.getItem("pageName")

    if (sessionStorage.getItem("ipaddress")) {

      fetch(environment.app.grp + "api/common/analytics", {

        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
          "ipAddress": sessionStorage.getItem("ipaddress"),
          "timestamp": sessionStorage.getItem("timeStamp"),
          "pageName": pageName ? pageName : "PersonalInfo",
          "gbReferer": window.document.referrer || "",
          "pageUrl": window.location.href
        }),

        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
    }

  }
  commonSettings() {
    //   fetch(environment.app.grp+"api/common/settings", { mode: 'cors' })
    //   .then((resp) => resp.json())
    //   .then((res) => {
    //     console.log(res)
    // // sessionStorage.setItem("ipaddress",ip.ip);

    //   });
  }
  getIPAddress() {
    fetch('https://jsonip.com', { mode: 'cors' })
      .then((resp) => resp.json())
      .then((ip) => {

        // //this.consoleMessage(ip)
        sessionStorage.setItem("ipaddress", ip.ip);

      });


  }

  initializeCanvas(): void {
    if (this.canvas) {
      const canvasElement = this.canvas.nativeElement;
      this.ctx = canvasElement.getContext('2d');
      // this.ctx.strokeStyle = 'red';
      if (this.ctx) {
        // console.log('Canvas context initialized successfully');
        this.resizeCanvas();
        this.setupResizeObserver();
      } else {
        // console.error('Failed to get 2D context');
      }
    } else {
      // console.error('Canvas element is not available');
    }
  }


  initializeCanvaspad(): void {
    if (this.canvaspad) {
      const canvasElementpad = this.canvaspad.nativeElement;
      this.ctxpad = canvasElementpad.getContext('2d');
      // this.ctxpad.strokeStyle = 'red';
      if (this.ctxpad) {
        // console.log('Canvas context initialized successfully');
        this.resizeCanvaspad();
      } else {
        // console.error('Failed to get 2D context');
      }
    } else {
      // console.error('Canvas element is not available');
    }
  }
  resizeCanvas(): void {
    if (this.canvas && this.ctx) {
      const canvasElement = this.canvas.nativeElement;
      canvasElement.width = canvasElement.offsetWidth;
      canvasElement.height = canvasElement.offsetHeight;
      // console.log('Canvas resized:', canvasElement.width, canvasElement.height);
    } else {
      // console.error('Canvas element or context is not available');
    }
  }
  resizeCanvaspad(): void {
    if (this.canvaspad && this.ctxpad) {
      const canvasElementpad = this.canvaspad.nativeElement;
      canvasElementpad.width = canvasElementpad.offsetWidth;
      canvasElementpad.height = canvasElementpad.offsetHeight;
      // console.log('Canvas resized:', canvasElementpad.width, canvasElementpad.height);
    } else {
      // console.error('Canvas element or context is not available');
    }
  }
  startDrawing(event: MouseEvent): void {
    if (this.ctx) {
      this.drawing = true;
      // this.ctx.beginPath();
      // this.ctx.moveTo(event.offsetX, event.offsetY);
      const coords = this.relativeCoords(event);
      this.ctx.moveTo(coords.x, coords.y);
      // console.log('Drawing started at:', event.offsetX, event.offsetY);
    } else {
      // console.error('Context not initialized');
    }

    this.isDrawing = true;

  }

  draw(event: MouseEvent): void {
    if (this.drawing && this.ctx) {
      const coords = this.relativeCoords(event);

      this.ctx.lineTo(coords.x, coords.y);
      this.ctx.stroke();

      // console.log('Drawing at:', event.offsetX, event.offsetY);
    }
  }

  stopDrawing(): void {
    if (this.drawing) {
      this.drawing = false;
      // console.log('Drawing stopped');
    }
  }

  clearCreditCardSignature() {
    const canvasElement = this.canvas.nativeElement;
    // this.ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    // this.ctx.beginPath();
    this.signaturecheckvalue1cc = false;
    sessionStorage.removeItem("signature")
    this.signaturemessagecc = true
    this.signaturecheckcc = true
  }

  clearPadSignature() { }

  save(): void {
    const canvasElement = this.canvas.nativeElement;
    const dataURL = canvasElement.toDataURL('image/png');
    // console.log(dataURL); // Use this data URL to save the image or send it to a server
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // console.log(this.signaturePad.toDataURL());
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signaturecheckvalue1cc = false;
  }

  saveSignature() {
    const base64Data = this.signaturePad.toDataURL();
    // console.log(base64Data);
    // You can send the base64Data to your server here
  }

  get payment(): { [key: string]: AbstractControl } {
    return this.paymentForm.controls;
  }
  get bankpay(): { [key: string]: AbstractControl } {
    return this.bankpayForm.controls;
  }

  setActiveTab(index: number) {
    // alert(index)
    this.previousTab = this.activeTab;
    this.activeTab = index;
    this.visitedTabs.add(this.previousTab);
  }
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: any) {
    this.drawing = false;
  }


  onMouseMove(e: any) {

    this.signaturecheckvalue1cc = true;
    this.signaturemessagecc = false
    this.showIAFGerrorMessage = false


  }
  onMouseDown(e: any) {
    this.isDrawing = true;
  }

  onMouseDownpad(e: any) {
    if (this.ctxpad) {
      this.isDrawingpad = true;
      const coords = this.relativeCoords(e);
      this.ctxpad.moveTo(coords.x, coords.y);
    }
  }

  onMouseMovepad(e: any) {
    if (this.isDrawingpad && this.ctxpad) {
      const coords = this.relativeCoords(e);
      this.ctxpad.lineTo(coords.x, coords.y);
      this.ctxpad.stroke();
      this.signaturecheckvalue1 = true;
      this.signaturemessagepad = false;
    }
  }


  private relativeCoords(event: any): { x: number, y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return { x, y };
  }

  alphabatesOnly(event: any) {
    const key = event.key || String.fromCharCode(event.keyCode);
    // Check if the key is an alphabetical character (A-Z or a-z) or a space
    if (/^[a-zA-ZÀ-ÿ ]*$/.test(key)) {
      return true;
    } else {
      return false;
    }
  }
  public paymentmethod(event: any) {
    // this.signaturePad.clear();
    // this.clearPadSignature()
    // this.clearCreditCardSignature()
    if (event.target.value == "PAD") {
      // this.bankpay = true;
      // this.creditcardform = false;
      sessionStorage.setItem("paymentMethod", "PAD");
      // $("#bankpay").prop("checked", true);

    } else {

      // this.bankpay = false;
      // this.creditcardform = true;
      sessionStorage.setItem("paymentMethod", "CC");
      // $("#creditcard").prop("checked", true);

      this.bankfile = ""
      this.imagedisplay = false
      this.imagedisplay = ""
      sessionStorage.removeItem("fileurl")
      sessionStorage.removeItem("filename")
      this.isFileUploaded = true
      this.Shared.setMessage("")
      this.bankpayForm.get('voidcheck')?.reset();

      // $('.paymentmethod').not(this).prop('checked', false);
    }
  }

  public formatCreditCardNumber(event: any): void {
    let inputVal = event.target.value.replace(/\s+/g, ''); // Remove existing spaces

    if (inputVal.length > 0) {
      // Format the input with spaces every 4 digits
      inputVal = inputVal.match(/.{1,4}/g).join(' ');
    }
    this.paymentForm.patchValue({ CardNumber: inputVal });

    // this.creditCardForm.patchValue({ CardNumber: inputVal });
  }
  public formatAmericaCreditCardNumber(event: any): void {
    let inputVal = event.target.value.replace(/\s+/g, ''); // Remove existing spaces

    if (inputVal.length > 0) {
      // Format the input with spaces every 4 digits
      // inputVal = inputVal.match(/.{1,4}/g).join(' ');

      inputVal = inputVal.replace(
        /(\d{4})(\d{1,6})?(\d{1,5})?/,
        function (match: any, p1: any, p2: any, p3: any) {
          return p1 + (p2 ? ' ' + p2 : '') + (p3 ? ' ' + p3 : '');
        }
      );
    }
    this.paymentForm.patchValue({ CardNumber: inputVal });
    // if (this.isValidAmexCard(inputVal.replaceAll(' ',''))) {
    //   console.log("This is a valid American Express card number.");
    // } else {
    //   console.log("This is not a valid American Express card number.");
    // }
    // this.creditCardForm.patchValue({ CardNumber: inputVal });
  }
  public isValidAmexCard(cardNumber: any) {
    // Remove any non-digit characters
    const cleanedCardNumber = cardNumber.replace(/\D/g, '');

    // Check if the card number has 15 digits and starts with 34 or 37
    const isAmex = /^3[47]\d{13}$/.test(cleanedCardNumber);

    return isAmex;
  }
  public isValidAmexCardNumber(val: any) {
    // Define the regex pattern for formatted Amex card number
    const amexPattern = /^3[47][0-9]{2} [0-9]{6} [0-9]{5}$/;
    console.log(amexPattern);
    return amexPattern.test(val.replaceAll(' ', ''));
  }
  public creditcardlblurmethod(event: any) {
    // this.postalvalue = event.target.value
    this.validateCreditCardNumber(event);

    let val = event.target.value;

    // console.log(val.slice(0, 2));

    const inputField = this.paymentForm.controls['CardNumber'];

    if (val.slice(0, 2) == 34 || val.slice(0, 2) == 37) {
      // this.creditcardvalidationAmerica(val)
      // inputField.setValidators([Validators.required, creditcardvalidationAmerica(val)]);
      // inputField.updateValueAndValidity();

      this.formatAmericaCreditCardNumber(event);
      // this.isValidAmexCardNumber(val)
    } else {
      // this.creditcardvalidation(val)
      // inputField.setValidators([Validators.required, creditcardvalidation(val)]);
      // inputField.updateValueAndValidity();

      this.formatCreditCardNumber(event);
    }

    val = val.replaceAll(' ', '');
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2
    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }
      // Add the units element to the checksum total
      checksum = checksum + calc;
      // Switch the value of j
      if (j == 1) {
        j = 2;
      } else {
        j = 1;
      }
    }
    //Check if it is divisible by 10 or not.
    // return (checksum % 10) == 0;
    if (checksum % 10 == 0) {
      this.invalidcreditcard = false;
    } else {
      this.invalidcreditcard = true;
      // console.log("tetshere")
    }

    // if (event.target.value.length == 0) {
    //   this.invalidcreditcard = false;
    //   consol.log("false")
    // }
  }

  validateCreditCardNumber(val: any) {
    const cardImage = document.getElementById('card-image');

    console.log(val.target.value);
    const visaPattern = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const mastPattern = /^(?:5[1-5][0-9]{14})$/;
    const amexPattern = /^(?:3[47][0-9]{13})$/;
    const discPattern = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

    const ccNum: string = val.target.value.replaceAll(' ', '');

    const isVisa = visaPattern.test(ccNum) === true;
    const isMast = mastPattern.test(ccNum) === true;
    const isAmex = amexPattern.test(ccNum) === true;
    const isDisc = discPattern.test(ccNum) === true;

    if (isVisa || isMast || isAmex || isDisc) {
      // at least one regex matches, so the card number is valid.
      if (isVisa) {
        // this.cardType = 'Visa card'
        this.cardType = './../../assets/img/visa.svg';
        // Visa-specific logic goes here
      } else if (isMast) {
        // this.cardType = 'Master card'
        this.cardType = './../../assets/img/mastercard.svg';
        // Mastercard-specific logic goes here
      } else if (isAmex) {
        this.cardType = './../../assets/img/american-express.svg';
        // cardImage['src']= 'path/to/amex.png';
        // cardImage.style.display = 'inline';
        // AMEX-specific logic goes here
      } else if (isDisc) {
        this.cardType = './../../assets/img/discover.svg';
        // Discover-specific logic goes here
      }
      // return of (null);
    } else {
      this.cardType = '';
      // cardImage.style.display = 'none';
      // return of({
      //   invalid: true
      // });
      // alert("Please enter a valid card number.");
    }
  }
  resolved(captchaResponse: any) {
    this.recaptchaResponse = captchaResponse;
  }
  filterExpiryDate(event: any) {
    let val = ''

    if (this.paymentForm.controls['ExpirationMonth'].value && this.paymentForm.controls['ExporationYear'].value) {
      val = this.paymentForm.controls['ExpirationMonth'].value + "/" + this.paymentForm.controls['ExporationYear'].value
    }
    // console.log('VAL', val);
    let regex: any = val.match(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/);

    if (regex) {
      // console.log('VALID format');
    } else {
      // console.log('INVALID format');
    }

    var exp = new Date(
      this.checkFullYearFormat(1 * regex[2]),
      1 * regex[1],
      1
    ).valueOf();
    var now = new Date();
    var currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
    // console.log(currMonth)
    if (exp <= currMonth) {
      this.invalidCardDetails = true
      // alert('Invalid Expiry Date. Date should be greater than current date');


    } else {
      this.invalidCardDetails = false
      // alert('Valid Expiry Date');
    }
  }

  checkFullYearFormat(yearVal: any) {
    var FIXED_YEAR = 50;
    if (yearVal < 100) {
      var nowYear = new Date().getFullYear();
      yearVal += Math.floor(nowYear / 100) * 100;
      if (yearVal > nowYear + FIXED_YEAR) {
        yearVal -= 100;
      } else if (yearVal <= nowYear - 100 + FIXED_YEAR) {
        yearVal += 100;
      }
    }
    return yearVal;
  }

  onClosedModelpopup(confirmed: boolean) {
    if (confirmed) {
      this.ShowModelPopup = false;
    } else {
      this.ShowModelPopup = false;
    }
  }

  public acceptAggrement() {
    setTimeout(() => {

      let inputData = {
        "url": sessionStorage.getItem("fileurl"),
        "filename": sessionStorage.getItem("filename"),
        "signature": sessionStorage.getItem("signature")
      }

      this._SignupformService.padsignature(inputData).subscribe((result: any) => {
        if (result.status == "200") {
          this.showPADModelDiaslog = true
          this.pdfData = result.data.url
          this.header = "PAD Agreement"
          sessionStorage.setItem("failed", "")
        } else {
          this.failedarray.push("updatePAD")
          //this.consoleMessage(this.failedarray)
          sessionStorage.setItem("failed", JSON.stringify(this.failedarray))
        }
      })
    }, 1000);
  }

  onCloseModal() {
    this.showModal = false;
  }
  onClose() {
    this.benficiary = false
  }

  public closeConfirmModel() {
    this.ConfirmModel = false
  }

  public checkbankdetails(event: any) {
    // this.bankdetailsverify =  true
    if (event.target.value == 'yes') {
    } else {
      this.bankpayForm.reset();
      this.imagedisplay = false;
      sessionStorage.removeItem('fileurl');
      sessionStorage.removeItem('filename');
      // this.bankpayForm.get('bankcode').value
    }

    this.verifybankdetails = false;
  }
  public verifybank() {
    this.banknameres = '';

    let inputData = {
      bankCode: this.bankpayForm.controls['banknumber'].value,
      branchCode: this.bankpayForm.controls['transitnumber'].value,
    };

    this._SignupformService.getbanknames(inputData).subscribe(
      (result: any) => {
        if (result.status == '200') {
          // this.bankdetailsverify =false
          this.verifybutton = false;
          this.bankverifyDetails = result.data;
          this.banknameres =
            result.data.bank.name +
            '\n' +
            result.data.address.split(',').join('\n');
          // this.invalidbankdetails = false;
        } else if (result.status == '202') {
          // this.toastrService.warning(result.message, '');
          this.bankerrormsg = result.message;
          // this.invalidbankdetails = true;
          this.verifybankdetails = false;
        } else {
          // this.toastrService.warning(result.message, '');
          this.bankerrormsg = result.message;
          // this.invalidbankdetails = true;
          this.verifybankdetails = false;
        }
      },
      (error) => {
        alert('Please check the provided bank details');
        // this.toastrService.warning(
        //   "Please check the provided bank details",
        //   ""
        // );
      }
    );
  }
  public touchedmsg() {
    // this.invalidbankdetails = false;
    this.bankerrormsg = ''
    if (
      this.bankpayForm.controls['banknumber'].value?.length == 3 &&
      this.bankpayForm.controls['transitnumber'].value?.length == 5 &&
      (this.bankpayForm.controls['accountnumber'].value?.length ?? 0) >= 5
    ) {
      this.verifybankdetails = true;
      this.verifybutton = true;
    } else {
      this.verifybankdetails = false;
      this.verifybutton = false;
    }
  }
  public voidcheckupload(event: any) {
    this.imagedisplay = false
    sessionStorage.removeItem('fileurl');
    sessionStorage.removeItem('filename');
    sessionStorage.removeItem('signature');
    var fileExtension = '.' + event.target.files[0].name.split('.').pop();

    // event.target.files[0].name ="void_cheque_"+Date.now()+fileExtension

    // "void_cheque_"+Date.now()+fileExt
    ////this.consoleMessage(event)
    this.isFileUploaded = true;
    // alert(event.target.files[0].name.replace(event.target.files[0].name,"void.pdf"))
    this.bankfile = event.target.files[0];
    this.Shared.setMessage(this.bankfile);
    // this.bankfile.name = (this.bankfile.name,"void_cheque_"+Date.now()+fileExtension)
    // alert(fileExtension.toLowerCase())
    var allowedMimes = [
      'image/jpeg',
      'image/pjpeg',
      'image/png',
      'application/pdf',
    ];

    let error = false;

    let message = '';

    if (allowedMimes.includes(this.bankfile.type)) {
      if (this.bankfile.size <= 1000 * 1024 * 5) {
        //300KB

        error = false;
      } else {
        error = true;
        // this.ShowModelPopup =true
        this.ShowModelPopupMessage = this.lang.File_size_is_too_large_maximum_file_size_is_5MB;
        // this.ShowModelPopupTitle =this.lang.Info
      }
    } else {
      error = true;
      // this.ShowModelPopup =true
      this.ShowModelPopupMessage = this.lang.Invalid_file_type_Only_jpg_png_image_pdf_files_are_allowed;
      // this.ShowModelPopupTitle =this.lang.Info
    }

    if (error) {
      // this.showDeleteModal = true;
      // this.modalMessage = message;

      this.ShowModelPopup = true
      this.ShowModelPopupMessage = this.ShowModelPopupMessage
      this.ShowModelPopupTitle = this.lang.Info
      this.bankpayForm.get('voidcheck')?.reset();
      this.isFileUploaded = true;
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      if (event.target.files[0].type == 'application/pdf') {
        // alert("1")
        this.pdfview = true;
        this.normalview = false;
      } else {
        this.pdfview = false;
        this.normalview = true;
      }

      reader.onload = (_event) => {
        this.imagedisplay = true
        this.imagedisplay = reader.result;
        console.log(this.imagedisplay)
      };

    }

  }

  public showmodelsign() {
    // console.log("tets1")
    this.generatePADaggrement = ""

    let inputData = {
      "url": sessionStorage.getItem("fileurl"),
      "filename": sessionStorage.getItem("filename"),
      "signature": sessionStorage.getItem("signature")
    }

    this._SignupformService.padsignature(inputData).subscribe((result: any) => {
      if (result.status == "200") {
        this.padAggrementFinalModel = true
        this.generatePADaggrement = result.data.url
        this.signaturestatus = false
        this.agreestatus = true
        sessionStorage.setItem("failed", "")

      } else {
        this.failedarray.push("updatePAD")
        sessionStorage.setItem("failed", JSON.stringify(this.failedarray))
      }
    })
  }

  public showpadaggrement() {
    if (
      sessionStorage.getItem('fileurl') &&
      sessionStorage.getItem('filename') &&
      sessionStorage.getItem('signature')
    ) {
      this.showmodelsign()

      console.log("sdasds")
    }
    else {
      console.log("sdasds2")
      let Data = JSON.parse(sessionStorage.getItem('Data') || '{}');
      this.agreestatus = false
      this.signaturestatus = true
      // alert(this.bankdetailsverify)
      this.bankpayForm.markAllAsTouched()

      let bankAddress = this.bankverifyDetails.address;
      let bankAdressArr = bankAddress.split(',');
      let bankCity = bankAdressArr[bankAdressArr.length - 2].replace(' ', '');
      let provincePostal = bankAdressArr[bankAdressArr.length - 1];
      let provincePostalArr = provincePostal.split(' ');
      let bankProvince = provincePostalArr[provincePostalArr.length - 3];
      let bankPostalCode = provincePostalArr[provincePostalArr.length - 2] +
        ' ' + provincePostalArr[provincePostalArr.length - 1];


      // console.log(this.bankpayForm.value)
      console.log({bankAddress,bankAdressArr,bankCity,provincePostal,provincePostalArr,bankProvince,bankPostalCode})
      if (this.bankverifyDetails != undefined) {
        const bankname = this.bankpayForm.value.bankname;
        const bankAdressArr1 = bankname?.split('\n');

        this.bankpayForm.get('bankstreetaddress')?.setValue(bankAdressArr[0]);
        // console.log(bankAdressArr1?.[2],bankAdressArr1?.[bankAdressArr1.length-3]);
        if (bankAdressArr1?.[2] != undefined && bankAdressArr1?.[2] == bankAdressArr1?.[bankAdressArr1.length - 3]) {
          this.bankpayForm.get('bankstreetaddressline2')?.setValue(bankAdressArr1?.[2]);
        } else {
          this.bankpayForm.get('bankstreetaddressline2')?.setValue('');
        }
        this.bankpayForm.get('bankcity')?.setValue(bankCity);
        this.bankpayForm.get('bankprovince')?.setValue(bankProvince);
        this.bankpayForm.get('bankpostalcode')?.setValue(bankPostalCode);
      }
      // console.log('this.bankverifyDetails',this.bankverifyDetails)
      // console.log(this.paymentForm.value)
      console.log('this.bankpayForm.value',this.bankpayForm.value)
      // return;
      if (this.bankpayForm.valid) {
        console.log('this.bankpayForm.valid')
        let addressobject = {
          address1 : this.bankpayForm.get('streetaddress')?.value ||'',
          address2 : this.bankpayForm.get('streetaddressline2')?.value || '',
          apt: this.bankpayForm.get('apt')?.value || '',
          country: this.bankpayForm.get('country')?.value || '',
          city: this.bankpayForm.get('city')?.value || '',
          province : this.bankpayForm.get('province')?.value || Data.province || '',
          postalCode : this.bankpayForm.get('postalcode')?.value || '',
          phone : this.bankpayForm.get('primaryPhone')?.value || '',
          email : this.bankpayForm.get('primaryEmail')?.value || '',
        };

        let bankobject = {
          name: this.bankverifyDetails.bank.name,
          address: this.bankverifyDetails.bank.address.split(',')[0],
          city: bankAdressArr[bankAdressArr.length - 2].replace(' ', ''),
          province: provincePostalArr[provincePostalArr.length - 3],
          postalCode:
            provincePostalArr[provincePostalArr.length - 2] +
            ' ' +
            provincePostalArr[provincePostalArr.length - 1],
        };
        console.log('bankobject', bankobject)

        let inputData1 = {
          bankCode: this.bankpayForm.get('banknumber')?.value,
          branchCode: this.bankpayForm.get('transitnumber')?.value,
          accountNumber: this.bankpayForm.get('accountnumber')?.value,
        };

        var fileExtension = '.' + this.bankfile.name.split('.').pop();

        var encBank = btoa(JSON.stringify(inputData1));
        var formData: any = new FormData();
        // formData.append('firstName', Data.firstName);
        // formData.append('lastName', Data.lastName);
        if (this.bankpayForm.get('firstName')?.value != undefined) {
          let firstName = this.capitalizeFirstChars(this.bankpayForm.get('firstName')?.value || '')
          formData.append('firstName', firstName);
        } else {
          formData.append('firstName', undefined);
        }
        if (this.bankpayForm.get('lastName')?.value != undefined) {
          let lastName = this.capitalizeFirstChars(this.bankpayForm.get('lastName')?.value || '')
          formData.append('lastName', lastName);
        } else {
          formData.append('lastName', undefined);
        }
        formData.append('address', JSON.stringify(addressobject));
        formData.append('bank', JSON.stringify(bankobject));
        formData.append(
          'files',
          this.bankfile,
          'void_cheque_' + Date.now() + fileExtension.toLowerCase()
        );
        // formData.append("files", this.bankfile.name,"void.pdf");
        formData.append('session', encBank) || '';
        formData.append('timestamp', new Date().getTime());

        // for (let [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }
        // return;
        this._SignupformService
          .padaddgrementdetails(formData)
          .subscribe((result: any) => {
            if (result.status == '200') {
              this.padAggrementModel = true
              this.generatePADaggrement = result.data.url

              sessionStorage.setItem('fileurl', result.data.url);
              sessionStorage.setItem('filename', result.data.filename);
            } else {



              this.ShowModelPopup = true
              this.ShowModelPopupMessage = result.message
                ? result.message
                : result.error
              this.ShowModelPopupTitle = this.lang.Info
            }
          });
      } else {
        console.log('this.bankpayForm.invalid')
        this.bankpayForm.markAllAsTouched();

        Object.keys(this.bankpayForm.controls).forEach(key => {
          const control = this.bankpayForm.get(key);
          console.log(`${key}:`, control?.invalid ? control.errors : 'Valid');
        });
      }
    }

    // this.createCutomerPADagreement(data)
  }

  public agreepadconditions() {
    this.acceptaggrepad = false
    this.padaggrementerror = ""
    this.padAggrementFinalModel = false
    this.signaturestatus = false
    this.agreestatus = false
    this.rejectPADModel = false
    this.closePADModel = true
    this.showagree = false
    // jQuery("#PADpafaggrement1-modal").modal("hide");

    // this.acceptaggrepad = false
    // this.padaggrementerror =""
    // this.showagree =false
    // this.showReject =false
    // this.showClose =true
  }

  public closePADAdggrementModel() {
    this.padAggrementModel = false
    this.padAggrementFinalModel = false
  }

  public rejectPADpafaggrement() {
    this.acceptaggrepad = true
    // this.padaggrementerror = "Please check the PAD aggrement"

    this.padAggrementModel = false
    this.padAggrementFinalModel = false

  }

  public rejectPADpafaggrement1() {
    this.acceptaggrepad = true
    // this.padaggrementerror = "Please check the PAD aggrement"

    this.padAggrementModel = false
    this.padAggrementFinalModel = false

  }

  public signaturestartmodel() {
    // jQuery("#drawsignaturemodel-modal").modal("show");
    this.signModelForBankPay = true
    // this.clearPadSignature();
    this.generatePADaggrement = ""
    // this.acceptaggrepad ==true
  }

  public savePadsignature() {
    this.acceptaggrepad = false
    // this.padaggrementerror =""
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (this.signatureNeeded == false) {
      const base64Data = this.signaturePad.toDataURL();
      // console.log(base64Data);
      sessionStorage.setItem("signature", base64Data);
    }
    else {
      sessionStorage.setItem("signature", "");
      this.showpaderrorMessage = true
    }
    setTimeout(() => {
      if (sessionStorage.getItem("signature")) {
        // if(sessionStorage.getItem("signature") && sessionStorage.getItem("signature")?.length>0){
        this.signModelForBankPay = false

        setTimeout(() => {
          this.padAggrementModel = false
          this.showmodelsign();

          // console.log("test")
        }, 10);
      }
      else {
        // this.signaturemessagepad = true;
        // this.signaturePad.clear();

        // console.log("tetshere")
      }
    }, 100);

  }

  public creditcardpaysubmit(value: any, value1: any) {

    this.signatureNeeded = this.signaturePad.isEmpty();
    if (this.signatureNeeded == false) {

      const base64Data = this.signaturePad.toDataURL();
      // console.log(base64Data);
      sessionStorage.setItem("signature", base64Data);

    }
    else {
      sessionStorage.setItem("signature", "");
      this.signaturemessagecc = true

    }
    let inputData = JSON.parse(sessionStorage.getItem("Data") || '{}');

    if (sessionStorage.getItem("Spousesignature")) {
      inputData.spouseSignature = sessionStorage.getItem("Spousesignature")
    }

    inputData.signature = sessionStorage.getItem("signature") || "";

    let provincelist = [];
    const storedProvincelist = sessionStorage.getItem("provincelist");
    if (storedProvincelist) {
      try {
        provincelist = JSON.parse(storedProvincelist);
      } catch (error) {
        console.error("Error parsing provincelist from sessionStorage:", error);
        // Handle the error or assign a fallback value
        provincelist = [];
      }
    } else {
      console.log("provincelist not found in sessionStorage, using default empty array.");
      // Assign a fallback empty array if sessionStorage is empty or not set
      provincelist = [];
    }
    console.log('next step')
    if (provincelist.includes(inputData.province_id)) {
    } else {
      if (this.formConfigResult?.assign_default_form == true) {

        inputData.formId = this.formConfigResult.default_form.id;
        inputData.brokershipTransfered = true;
        inputData.originalFormId = this.formConfigResult.form.id;
      } else {
      }
    }

    if (sessionStorage.getItem("fusebillCustomerAddressId")) {
      inputData.fusebillCustomerAddressId =
        sessionStorage.getItem("fusebillCustomerAddressId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.fusebillCustomerId =
        sessionStorage.getItem("fusebillCustomerId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerBillingAddressId")
    ) {
      inputData.fusebillCustomerBillingAddressId =
        sessionStorage.getItem("fusebillCustomerBillingAddressId") || null;
    }
    if (
      sessionStorage.getItem("customerId")
    ) {
      inputData.customerId = sessionStorage.getItem("customerId") || null;
      // inputData.billing_address.customer_id= parseInt(sessionStorage.getItem("customerId")) || null
    }
    inputData.billing_sameas_homeAddress =
      sessionStorage.getItem("homeAddress") == "true" ? true : false;

    if (sessionStorage.getItem("homeAddress") == "true") {
      inputData.billing_address = {
        // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
        // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
        line1: inputData.street_address_line1 || "",
        line2: inputData.street_address_line2 || "",
        city: inputData.city || "",
        state: inputData.province || "",
        state_id: parseInt(this.provineList.id), // this.fusebillid = parseInt(element.fusebillId);
        // ostate_id:this.state_id
        fusebillStateId: parseInt(this.provineList.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: inputData.postal_code || "",
        apt: inputData.apt || "",
      };
    } else {
      inputData.billing_address = {
        // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
        // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
        line1: this.paymentForm.get("streetaddress")?.value || "",
        line2: this.paymentForm.get("streetaddressline2")?.value || "",
        city: this.paymentForm.get("city")?.value || "",
        state: this.paymentForm.get("province")?.value || "",
        state_id: parseInt(this.provineListcreditPayment?.id),
        // this.fusebillid = parseInt(element.fusebillId);
        // ostate_id:this.state_id
        fusebillStateId: parseInt(this.provineListcreditPayment?.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: this.paymentForm.get("postalcode")?.value || "",
        apt: this.paymentForm.get("aptcheck")?.value || "",
      };
    }

    if (
      sessionStorage.getItem("customerId") &&
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.billing_address.customer_id =
        parseInt(sessionStorage.getItem("customerId") || '0') || null;
      inputData.billing_address.fusebill_customer_id =
        parseInt(sessionStorage.getItem("fusebillCustomerId") || '0') || null;
    }

    // ////this.consoleMessage(inputData);
    if (this.paymentForm.invalid) {
      console.log('this.paymentForm.invalid')
      const invalid = [];
      this.paymentForm.markAllAsTouched();
      const controls: any = this.paymentForm.controls;
      for (let name in controls) {
        //this.consoleMessage(name)
        if (controls[name].invalid) {

          if (name == "CardNumber") {
            name = this.lang.Enter_Card_Number;
          }
          if (name == "ExpirationMonth") {
            name = this.lang.Select_Expiry_Month;
          }
          if (name == "ExporationYear") {
            name = this.lang.Select_Expiry_Year;
          }
          if (name == "cvv") {
            name = this.lang.Enter_CVV
          }
          // if (name == "streetaddress") {
          //   name = this.lang.Enter_Street_Address;
          // }
          // if (name == "city") {
          //   name = this.lang.Enter_City;
          // }
          // if (name == "province") {
          //   name = this.lang.Select_Province;
          // }
          // if (name == "postalcode") {
          //   name = this.lang.Enter_Postal_Code;
          // }

          if (name == "recaptchaReactive") {
            name = this.lang.Select_Recaptcha;
          }


          invalid.push(name);
        }

      }
      this.missingInformation = true
      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      console.log('invalid', invalid)
      return invalid;
    }
    console.log('this.paymentForm.valid', this.paymentForm.value)
    console.log('this.signatureNeeded', this.signatureNeeded)
    if (this.signatureNeeded == true) {
      const invalid = [];
      invalid.push("Draw the Signature");
      this.missingInformation = false

      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }

    if (
      this.paymentForm.valid && this.signatureNeeded == false
    ) {
      console.log('this.paymentForm.valid && this.signatureNeeded == false')

      value1.submit();

    } else {
      if (this.signaturecheckcc == true) {
        this.signaturemessagecc = true;
      }
    }
  }
  public bankpayformsubmit(value: any) {
    let inputData = JSON.parse(sessionStorage.getItem("Data") || '{}');

    if (sessionStorage.getItem("Spousesignature")) {
      inputData.spouseSignature = sessionStorage.getItem("Spousesignature")
    }
    inputData.signature = sessionStorage.getItem("signature") || "";

    // let provincelist = JSON.parse(sessionStorage.getItem("provincelist") || "");
    let provincelist = [];
    const storedProvincelist = sessionStorage.getItem("provincelist");
    if (storedProvincelist) {
      try {
        provincelist = JSON.parse(storedProvincelist);
      } catch (error) {
        console.error("Error parsing provincelist from sessionStorage:", error);
        // Handle the error or assign a fallback value
        provincelist = [];
      }
    } else {
      console.log("provincelist not found in sessionStorage, using default empty array.");
      // Assign a fallback empty array if sessionStorage is empty or not set
      provincelist = [];
    }
    console.log('next step1')

    if (provincelist.includes(inputData.province_id)) {
    } else {
      if (this.formConfigResult?.assign_default_form == true) {
        // alert("1");
        inputData.formId = this.formConfigResult.default_form.id;
        inputData.brokershipTransfered = true;
        inputData.originalFormId = this.formConfigResult.form.id;
      } else {
      }
    }

    if (
      sessionStorage.getItem("fusebillCustomerAddressId")
    ) {
      inputData.fusebillCustomerAddressId =
        sessionStorage.getItem("fusebillCustomerAddressId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.fusebillCustomerId =
        sessionStorage.getItem("fusebillCustomerId") || null;
    }
    if (
      sessionStorage.getItem("fusebillCustomerBillingAddressId")
    ) {
      inputData.fusebillCustomerBillingAddressId =
        sessionStorage.getItem("fusebillCustomerBillingAddressId") || null;
    }
    if (
      sessionStorage.getItem("customerId")
    ) {
      inputData.customerId = sessionStorage.getItem("customerId") || null;
    }
    inputData.billing_sameas_homeAddress =
      sessionStorage.getItem("homeAddress") == "true" ? true : false;

    if (sessionStorage.getItem("homeAddress") == "true") {
      inputData.billing_address = {
        // "customer_id": parseInt(sessionStorage.getItem("customerId")) || null,
        // "fusebill_customer_id": parseInt(sessionStorage.getItem("fusebillCustomerId")) || null,
        line1: inputData.street_address_line1,
        line2: inputData.street_address_line2,
        city: inputData.city,
        state: inputData.province,
        state_id: parseInt(this.provineList.id), // this.fusebillid = parseInt(element.fusebillId);
        fusebillStateId: parseInt(this.provineList.fusebillId),
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: inputData.postalcode,
        apt: inputData.apt || "",
      };
    } else {

      //this.consoleMessage(this.paymentForm)
      inputData.billing_address = {
        line1: this.bankpayForm.get("bankstreetaddress")?.value || "",
        line2: this.bankpayForm.get("bankstreetaddressline2")?.value || "",
        city: this.bankpayForm.get("bankcity")?.value || "",
        state: this.bankpayForm.get("bankprovince")?.value || "",
        state_id: parseInt(this.provineListPayment?.id) || '',
        fusebillStateId: parseInt(this.provineListPayment?.fusebillId) || '',
        fusebillCountryId: 124,
        country: "Canada",
        country_id: 1,
        // ocountry_id: 1,
        postal_zip: this.bankpayForm.get("bankpostalcode")?.value,
        apt: this.bankpayForm.get("bankaptcheck")?.value || "",
      };
    }

    if (
      sessionStorage.getItem("customerId") &&
      sessionStorage.getItem("fusebillCustomerId")
    ) {
      inputData.billing_address.customer_id =
        parseInt(sessionStorage.getItem("customerId") || "0") || null;
      inputData.billing_address.fusebill_customer_id =
        parseInt(sessionStorage.getItem("fusebillCustomerId") || "0") || null;


    }
    if (this.acceptaggrepad == true) {
      this.padaggrementerror = this.lang.Please_check_the_PAD_agreement
    }
    else {
      this.padaggrementerror = ""
    }
    console.log(this.bankpayForm)
    if (this.bankpayForm.invalid) {
      console.log('this.bankpayForm.invalid')
      // this.bankpayForm.markAsTouched();
      this.bankpayForm.markAllAsTouched();
      const invalid = [];
      const controls: any = this.bankpayForm.controls;
      for (let name in controls) {
        //this.consoleMessage(name)
        if (controls[name].invalid) {

          if (name == "banknumber") {
            name = this.lang.Enter_Bank_Number;
          }
          if (name == "transitnumber") {
            name = this.lang.Enter_Transit_Number;
          }
          if (name == "accountnumber") {
            name = this.lang.Enter_Account_Number;
          }
          if (name == "bankname") {
            name = this.lang.Verify_the_bank_details;
          }

          if (name == "voidcheck") {
            name = this.lang.Uplaod_VoidCheck;
          }
          if (name == "bankstreetaddress") {
            name = this.lang.Enter_Street_Address;
          }
          if (name == "bankcity") {
            name = this.lang.Enter_City;
          }
          if (name == "bankprovince") {
            name = this.lang.Select_Province;
          }
          if (name == "bankpostalcode") {
            name = this.lang.Enter_Postal_Code;
          }
          if (name == "recaptchaReactive") {
            name = this.lang.Select_Recaptcha;
          }
          if (name == "streetaddress") {
            name = this.lang.Enter_Street_Address;
          }
          if (name == "city") {
            name = this.lang.Enter_City;
          }
          if (name == "province") {
            name = this.lang.Select_Province;
          }
          if (name == "postalcode") {
            name = this.lang.Enter_Postal_Code;
          }
          invalid.push(name);
        }

      }
      // jQuery("#missingInfo-modal").modal("show");
      this.missingInformation = true
      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }
    console.log('this.bankpayForm.valid')
    console.log('this.acceptaggrepad', this.acceptaggrepad)
    if (this.acceptaggrepad == true) {
      const invalid = [];
      invalid.push("Accept the PAD");
      // jQuery("#missingInfo-modal").modal("show");
      this.missingInformation = true
      this.missingInfo = invalid;
      //this.consoleMessage(invalid)
      return invalid;
    }

    console.log('this.bankpayForm.value', this.bankpayForm.value)
    console.log(this.bankpayForm.valid, this.cartcheckvalue)
    // if (this.bankpayForm.valid && this.cartcheckvalue == false) {
    if (this.bankpayForm.valid) {
      console.log('this.bankpayForm.valid && this.cartcheckvalue == false')
      let enrollmentData = {
        // "customerName": inputData.firstName + "" + inputData.lastName,
        "customerName": this.bankpayForm.get('firstName')?.value + " " + this.bankpayForm.get('lastName')?.value,
        "contactNum": inputData.phone_number,
        "dob": inputData.date_of_birth,
        "email": inputData.email,
        "process": "enrollmentSumamry",
        "request": {
          "paymentMethod": "PAD",
          "signature": sessionStorage.getItem("signature") || "",
          "bankCode": this.bankpayForm.get("banknumber")?.value,
          "branchCode": this.bankpayForm.get("transitnumber")?.value,
          "voidcheque": this.imagedisplay
        }
      }

      // this._SignupformService.savePlan(enrollmentData).subscribe((result: any) => {
      //   //this.consoleMessage(result)
      // })

      console.log({ enrollmentData })

      const now = new Date(Date.now());

      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
      const day = String(now.getDate()).padStart(2, '0'); // Ensure two digits

      const today = `${year}-${month}-${day}`;

      const filename = sessionStorage.getItem("filename")?.split('_') ?? []
      const filteredFileName = filename?.[filename.length - 1]
      const filetype = this.bankfile?.type?.split('/')[1]
      const inputData1 = {
        "bankCode": this.bankpayForm.get("banknumber")?.value,
        "branchCode": this.bankpayForm.get("transitnumber")?.value,
        "accountNumber": this.bankpayForm.get("accountnumber")?.value,
        "enrollmentDate": today,
        "name": this.bankpayForm.get('firstName')?.value + " " + this.bankpayForm.get('lastName')?.value,
        "customerId": "0",
        "fusebillCustomerId": this.customerid,
        "filenamets": filteredFileName,
        "amount": "0",
        "timestamp": new Date().toISOString(),
        "mimetype": this.bankfile.type,
        "padAgreement": this.generatePADaggrement,
        "voidCheckPath": filteredFileName + '.' + filetype
      }

      const inputData2 = {
        paymentMethod: 'PAD',
        igbmsId: this.customerid,
        paymentMethodId: '0'
      }
      console.log(inputData1)
      // return;
      this._SignupformService.customerAch(inputData1).subscribe((response: any) => {
        if (response.status == 200) {
          this._SignupformService.updatepayment(inputData2).subscribe((response: any) => {
            if (response.status == 200) {
              Swal.fire({ title: 'Info', text: response.message }).then(() => {
                if(this.isCorporate){
                  // Navigate to corporate portal after the alert is closed
                  window.location.href = `https://${this.corporateSubDomain}.testcorporate.groupbenefitz.aitestpro.com/login`;
                  // this.paymentForm.reset()
                  // this.bankpayForm.reset()
                }else{
                  // Navigate to Member portal after the alert is closed
                  window.location.href = environment.app.memberPortal + 'login';
                }
              });
            } else {
              Swal.fire({ title: 'Error', text: response.error.message }).then(() => { });
            }
          })
        } else {
          Swal.fire({ title: 'Error', text: response.error.message }).then(() => { });
        }
      })

      // this.bankformsubmitdetails();

    }
    else {
      console.log('!this.bankpayForm.valid && this.cartcheckvalue == false')
      this.bankpayForm.markAllAsTouched();
      if (this.signaturecheck == true) {
        this.signaturemessage = true;
      }
      if (this.cartcheckvalue == true) {
        this.ShowModelPopup = true
        this.ShowModelPopupMessage = this.lang.Please_check_the_Plan_Selection_Page
        this.ShowModelPopupTitle = this.lang.Info
      }
    }
  }

  public bankformsubmitdetails() {
    var formData: any = new FormData();

    let personalInfo = JSON.parse(sessionStorage.getItem("Data") || '{}');

    let bankinputData = {
      bankCode: this.bankpayForm.get("banknumber")?.value,
      branchCode: this.bankpayForm.get("transitnumber")?.value,
      bankName: this.bankpayForm.get("bankname")?.value,
      accountNumber: this.bankpayForm.get("accountnumber")?.value,
    };

    let inputData = {
      customerId: parseInt(sessionStorage.getItem("normalcustomerid") || '0'),
      bankCode: this.bankpayForm.get("banknumber")?.value,
      branchCode: this.bankpayForm.get("transitnumber")?.value,
      accountNumber: this.bankpayForm.get("accountnumber")?.value,

      amount: this._decimalPipe.transform(sessionStorage.getItem("totalAmount"), "1.2-2")?.replace(/,/g, ""),
      totalAmount: this._decimalPipe.transform(sessionStorage.getItem("totalAmount"), "1.2-2")?.replace(/,/g, ""),

      totalAmountUI: sessionStorage.getItem("totalAmountUI"),
      enrollmentDate: sessionStorage.getItem("enrollmentdate"),
      customerName: personalInfo.firstName + " " + personalInfo.lastName,
    };

    var encBank = btoa(JSON.stringify(inputData));
    sessionStorage.setItem("session", encBank);
    let link = sessionStorage.getItem("formlink")

    // this.router.navigate([link+"/paymentResponse"]);
    // this.router.navigate(["/payment/form"]);

    return false;

  }

  numbersOnly(event: any) {
    const key = event.key || String.fromCharCode(event.keyCode);
    // Check if the key is an alphabetical character (A-Z or a-z) or a space
    if (/(^[0-9 \-]+$)/.test(key)) {
      return true;
    } else {
      return false;
    }
  }


  public capitalize(str: String) {
    if (str) {
      // return str.charAt(0).toUpperCase() + str.slice(1);
      const words = str.split(' ');

      let finalres: any = words.filter((v) => v != '');
      return finalres
        .map((word: any) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
    }
  }
  public capitalizeFirstLetter(str: String) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    }
  }
  public capitalizeFirstChars(inputString: String) {
    return inputString
      // Split the string into words
      .split(' ')
      // Capitalize the first letter of each word
      .map(word =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      // Join the words back into a string
      .join(' ');
  }
  public showvoidcheckpreview() {
    this.imageDisplayShow = true

  }
  public closeImagePreview() {
    this.imageDisplayShow = false
  }

  public provincelistCreditPayment(event: any) {
    this.configprovinceres.forEach((element: any) => {
      if (element.shortName == event.target.value) {
        this.provineListcreditPayment = element;
      }
    });
    if (this.bankpayForm.controls['postalcode'].value) {
      if (
        this.provineListcreditPayment.zipcodes
          .split(',')
          .indexOf((this.bankpayForm.controls['postalcode'].value)?.[0]) == -1
      ) {
        this.creditCradInvalidProvince = true;
        this.bankpayForm.controls['postalcode'].markAsTouched();
      } else {
        this.creditCradInvalidProvince = false;
      }

      if (this.bankpayForm.controls['postalcode'].value.length == 0) {
        this.creditCradInvalidProvince = false;
      }
    }
    console.log('this.creditCradInvalidProvince',this.creditCradInvalidProvince)
  }

  changeTextToUppercaseCreditPayment(field: any, event: any) {
    const obj: any = {};
    obj['postalcode'] =
      this.bankpayForm.controls['postalcode'].value?.toUpperCase();

    this.bankpayForm.patchValue(obj);
    if (
      this.provineListcreditPayment.zipcodes
        .split(',')
        .indexOf(event.target.value[0]) == -1
    ) {
      this.creditCradInvalidProvince = true;
    } else {
      this.creditCradInvalidProvince = false;
      var postalRegexp = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/gm;
      if (event.target.value && !postalRegexp.test(event.target.value)) {
        this.creditCradInvalidProvince = true;
      } else {
        this.creditCradInvalidProvince = false;
      }
    }

    if (event.target.value.length == 0) {
      this.creditCradInvalidProvince = false;
    }
  }
}


export function validatehoursperweek(control: AbstractControl): any {
  if (control.value && control.value < 20) {
    return { mininvalidhours: true };
  } else if (control.value && control.value >= 81) {
    return { maxinvalidhours: true };
  }
}
export function hourselectioncondition(control: AbstractControl): any {
  if (control.value && control.value === 'false') {
    return { invalidhoursselection: true };
  }
}
export function creditcardvalidation(control: AbstractControl): any {
  if (control.value.slice(0, 2) == 34 || control.value.slice(0, 2) == 37) {
    var creditcardRegexp = /^[0-9_ ]{17,17}$/;
    if (control.value && !creditcardRegexp.test(control.value)) {
      return { invalidcreditcarddetails: true };
    }
  } else {
    var creditcardRegexp = /^[0-9_ ]{19,19}$/;
    if (control.value && !creditcardRegexp.test(control.value)) {
      return { invalidcreditcarddetails: true };
    }
  }
}
export function creditcardvalidationAmerica(control: AbstractControl): any { }
export function checkbanknumber(control: AbstractControl): any {
  if (control.value && control.value.length != 3) {
    return { checkbanknumbervalidator: true };
  }
}
export function checktransitnumber(control: AbstractControl): any {
  if (control.value && control.value.length != 5) {
    return { checktransitnumbervalidator: true };
  }
}

export function checkaccountnumber(control: AbstractControl): any {
  if (
    control.value &&
    (control.value.length < 5 || control.value.length > 12)
  ) {
    return { checkaccountnumbervalidator: true };
  }
}
export function emailValidator(control: AbstractControl): any {
  var emailRegexp =
    /^(?!\\s)*[\sA-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,3}(?!\\s)*$/i;

  // var emailRegexp =
  //   /^(?!\\s)[_A-Za-z0-9._%+-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$/i;

  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}
