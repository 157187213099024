<!-- <div class="space-top"></div>

<p class="title">Payment Method</p>

<div class="payment">
    <lib-credit-card></lib-credit-card>
</div> -->

<div class="payment-method pt-6 mx-1 sm:mx-12">
  <form
    [formGroup]="paymentForm"
    #paymentform2
    action="https://payments.subscriptionplatform.com/v2/payments/create"
    method="post"
  >
    <!-- <h5>{{ lang.Payment_Method }}</h5> -->
    <h5>Update payment method</h5>
    <div class="col-span-1">
      <input
        class="form-control"
        autofocus="autofocus"
        id="CustomerId"
        formcontrolname="cutomerId"
        name="CustomerID"
        type="hidden"
        value="{{ customerid }}"
      />
    </div>
    <div class="col-span-1">
      <input
        class="form-control"
        id="PublicApiKey"
        formcontrolname="PublicApiKey"
        name="PublicApiKey"
        type="hidden"
        value="{{ publicapikey }}"
      />
    </div>

    <div class="col-span-1">
      <input
        class="form-control"
        id="SuccessUri"
        formcontrolname="SuccessUri"
        name="SuccessUri"
        type="hidden"
        value="{{ paymentsuccessuri }}"
      />
    </div>

    <div class="col-span-1">
      <input
        class="form-control"
        id="FailUri"
        formcontrolname="FailUri"
        name="FailUri"
        type="hidden"
        value="{{ paymentUri }}"
      />
    </div>

    <div class="col-span-1">
      <input
        class="form-control"
        id="MakeDefault"
        placeholder="Name on the card"
        value="true"
        type="hidden"
        name="MakeDefault"
      />
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
      <!-- <div class="col-span-3">
        <label for="eamil"
          >{{ lang.Email }}<span style="color: red">*</span></label
        >

        <input
          class="form-control"
          id="Email"
          placeholder="{{ lang.Email }}"
          type="hidden"
          name="email"
          [(ngModel)]="paymentemail"
          [value]="paymentemail"
          [ngModelOptions]="{ standalone: true }"
        />
        <input
          class="form-control"
          id="Email"
          placeholder="{{ lang.Email }}"
          type="email"
          readonly
          [(ngModel)]="paymentemail"
          formControlName="email"
          [value]="paymentemail"
        />
      </div> -->

      @if(paymentMethod==undefined||paymentMethod==''){
      <div class="col-span-3">
        <!-- <div>
              <label for="cardnumnber">{{ lang.Payment_Method }}</label>
            </div> -->
        <div class="flex items-center space-x-2">
          <input
            type="radio"
            id="true"
            formControlName="paymentRadioCheck"
            value="PAD"
            class="form-radio text-blue-600"
            (click)="paymentmethod($event)"
          />
          <label for="true" class="text-gray-700">Pre Authorized Debit</label>
        </div>

        <div class="flex items-center space-x-2">
          <input
            type="radio"
            id="false"
            formControlName="paymentRadioCheck"
            value="CC"
            class="form-radio text-blue-600"
            (click)="paymentmethod($event)"
          />
          <label for="false" class="text-gray-700">{{
            lang.Credit_Card
          }}</label>
        </div>
      </div>
      }
    </div>

    @if(paymentForm.get('paymentRadioCheck')?.value === 'CC') {
    <div
      class="grid grid-cols-1 sm:grid-cols-3 gap-1 border-[1px] border-indigo-700 backdrop-blur-sm rounded-xl px-2 py-2"
    >
      <div class="col-span-3 sm:col-span-1">
        <label for="firstname">
          {{ lang.First_Name }}<span style="color: red">*</span></label
        >
        <input
          class="form-control"
          id="firstname"
          placeholder="{{ lang.First_Name }}"
          name="FirstName"
          type="hidden"
          [value]="paymentFirstName"
          [(ngModel)]="paymentFirstName"
          [ngModelOptions]="{ standalone: true }"
        />
        <input
          class="form-control"
          id="firstname"
          placeholder="{{ lang.First_Name }}"
          formControlName="FirstName"
          type="text"
          [(ngModel)]="paymentFirstName"
          (keypress)="alphabatesOnly($event)"
        />
        @if (payment['FirstName'].errors && payment['FirstName'].touched) {
        <div class="invalid text-red-500">
          @if (payment['FirstName'].errors['required']) {
          <div>{{ lang.Please_enter_first_name }}</div>
          }@if (payment['FirstName'].errors['minlength']) {
          <div>First Name must be atleast 3 characters</div>
          }
        </div>
        }
      </div>
      <div class="col-span-3 sm:col-span-1">
        <label for="lastname"
          >{{ lang.Last_Name }}<span style="color: red">*</span></label
        >
        <input
          class="form-control"
          id="LastName"
          placeholder="{{ lang.Last_Name }}"
          name="LastName"
          type="hidden"
          [value]="paymentLastName"
          [(ngModel)]="paymentLastName"
          [ngModelOptions]="{ standalone: true }"
        />
        <input
          class="form-control"
          id="LastName"
          placeholder="{{ lang.Last_Name }}"
          formControlName="LastName"
          type="text"
          [(ngModel)]="paymentLastName"
          (keypress)="alphabatesOnly($event)"
        />
        @if (payment['LastName'].errors && payment['LastName'].touched) {
        <div class="invalid text-red-500">
          @if (payment['LastName'].errors['required']) {
          <div>{{ lang.Please_enter_last_name }}</div>
          }@if (payment['LastName'].errors['minlength']) {
          <div>Last Name must be atleast 3 characters</div>
          }
        </div>
        }
      </div>
      <div class="col-span-3 sm:col-span-1 animate-pulse content-end">
        @if(cardType){
        <img class="w-20 h-14" src="{{ cardType }}" />
        }
      </div>

      <div class="col-span-2">
        <label for="cardnumnber"
          >{{ lang.Card_Number }}<span style="color: red">*</span></label
        >
        <input
          class="form-control"
          id="CardNumber"
          placeholder="{{ lang.Card_Number }}"
          name="CardNumber"
          formControlName="CardNumber"
          (input)="creditcardlblurmethod($event)"
          (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
        />

        @if (payment['CardNumber'].errors && payment['CardNumber'].touched) {
        <div class="invalid text-red-500">
          @if (payment['CardNumber'].errors['required']) {
          <div>{{ lang.Please_enter_Card_number }}</div>
          } @if (payment['CardNumber'].errors['invalidcreditcarddetails']) {
          <div>{{ lang.Please_enter_valid_Card_number }}</div>
          } @else{ @if(invalidcreditcard==true){

          <div class="invalid text-red-500">
            {{ lang.Please_enter_valid_Card_number }}
          </div>

          } }
        </div>
        }
      </div>
      <div class="col-span-1"></div>
      <div class="col-span-3 sm:col-span-1">
        <label for="expirymonth"
          >{{ lang.Expiry_Month }}<span style="color: red">*</span></label
        >

        <select
          class="form-control"
          id="ExpirationMonth"
          name="ExpirationMonth"
          (change)="filterExpiryDate($event)"
          formControlName="ExpirationMonth"
          placeholder="MM"
        >
          <option value="" disabled selected>
            {{ lang.Select }}
          </option>
          <option value="01">Jan</option>
          <option value="02">Feb</option>
          <option value="03">Mar</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">Aug</option>
          <option value="09">Sept</option>
          <option value="10">Oct</option>
          <option value="11">Nov</option>
          <option value="12">Dec</option>
        </select>
        @if (payment['ExpirationMonth'].errors &&
        payment['ExpirationMonth'].touched) {
        <div class="invalid text-red-500">
          @if (payment['ExpirationMonth'].errors['required']) {
          <div>{{ lang.Please_select_expiry_month }}</div>
          } @if (payment['ExpirationMonth'].errors['min']) {
          <div>minim</div>
          } @if (payment['ExpirationMonth'].errors['max']) {
          <div>maxmim</div>
          }
        </div>
        }
      </div>
      <div class="col-span-3 sm:col-span-1">
        <label for="expiryyear"
          >{{ lang.Expiry_Year }}<span style="color: red">*</span></label
        >

        <select
          class="form-control"
          id="ExpirationYear"
          placeholder="YY"
          name="ExpirationYear"
          (change)="filterExpiryDate($event)"
          formControlName="ExporationYear"
        >
          <option value="" disabled selected>
            {{ lang.Select }}
          </option>
          <option
            *ngFor="let item of [].constructor(40); let i = index"
            value="{{ i + 24 }}"
          >
            {{ i + 2024 }}
          </option>
        </select>
        @if (payment['ExporationYear'].errors &&
        payment['ExporationYear'].touched) {
        <div class="invalid text-red-500">
          @if (payment['ExporationYear'].errors['required']) {
          <div>{{ lang.Please_select_expiry_year }}</div>
          } @if (payment['ExporationYear'].errors['expiryDate']) {
          <div>cant minimminim</div>
          }
        </div>
        }
      </div>

      <div class="col-span-3 sm:col-span-1">
        <label for="cvv">{{ lang.CVV }}<span style="color: red">*</span></label>

        <input
          class="form-control"
          id="Cvv"
          placeholder="{{ lang.CVV }}"
          name="Cvv"
          formControlName="cvv"
          maxlength="4"
          (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
        />
        @if (payment['cvv'].errors && payment['cvv'].touched) {
        <div class="invalid text-red-500">
          @if (payment['cvv'].errors['required']) {
          <div>{{ lang.Please_enter_cvv }}</div>
          } @if (payment['cvv'].errors['minlength']) {
          <div>{{ lang.CVV_must_be_3_or_4_digits }}</div>
          }
        </div>
        }
      </div>

      @if(invalidCardDetails){
      <div class="col-span-3">
        <span class="invalid text-red-500">{{
          lang.Please_enter_the_correct_Month_and_Year
        }}</span>
      </div>
      }
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
      <div class="col-span-3">
        <label for="signature"
          >{{ lang.Signature }}<span style="color: red">*</span></label
        >

        <div
          class="signature-container sm:w-1/2"
          style="border: 1px solid #ccc; border-radius: 4px"
        >
          <signature-pad
            [options]="signaturePadOptions"
            (touchmove)="onMouseMove($event)"
            (mousemove)="onMouseMove($event)"
            (onEndEvent)="drawComplete()"
            (onBeginEvent)="drawStart()"
          ></signature-pad>
          @if(signaturecheckvalue1cc){
          <div
            style="
              left: 10px;
              color: red;
              text-decoration: underline;
              cursor: pointer;
            "
          >
            <p (click)="clearSignature()">x {{ lang.Clear }}</p>
          </div>
          }
        </div>
        @if(signaturemessagecc){
        <span class="invalid text-red-500">{{
          lang.Please_Sign_the_Signature
        }}</span>
        }
      </div>
      <div class="col-span-3">
        <re-captcha
          (resolved)="resolved($event)"
          formControlName="recaptchaReactive"
          siteKey="{{ fusbillinfocaptchavalue }}"
        >
        </re-captcha>

        <input
          id="recaptcha_token"
          type="hidden"
          name="recaptcha_token"
          [value]="recaptchaResponse"
        />
        @if (payment['recaptchaReactive'].errors &&
        payment['recaptchaReactive'].touched) {
        <div class="invalid text-red-500">
          @if (payment['recaptchaReactive'].errors['required']) {
          <div>{{ lang.Please_Check_re_Captcha_Validation }}</div>
          }
        </div>
        }
      </div>
    </div>

    } @if(paymentForm.get('paymentRadioCheck')?.value === 'PAD') {
    <form [formGroup]="bankpayForm" #bankForm="ngForm">
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
        <div class="col-span-3 sm:col-span-1">
          <label for="firstName">
            {{ lang.First_Name }}<span style="color: red">*</span></label
          >
          <input
            class="form-control"
            id="firstName"
            placeholder="{{ lang.First_Name }}"
            formControlName="firstName"
            type="text"
            (keypress)="alphabatesOnly($event)"
          />
          @if (bankpay['firstName'].errors && bankpay['firstName'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['firstName'].errors['required']) {
            <div>{{ lang.Please_enter_first_name }}</div>
            }@if (bankpay['firstName'].errors['minlength']) {
            <div>First Name must be atleast 3 characters</div>
            }
          </div>
          }
        </div>
        <div class="col-span-3 sm:col-span-1">
          <label for="lastName"
            >{{ lang.Last_Name }}<span style="color: red">*</span></label
          >
          <input
            class="form-control"
            id="lastName"
            placeholder="{{ lang.Last_Name }}"
            formControlName="lastName"
            type="text"
            (keypress)="alphabatesOnly($event)"
          />
          @if (bankpay['lastName'].errors && bankpay['lastName'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['lastName'].errors['required']) {
            <div>{{ lang.Please_enter_last_name }}</div>
            }@if (bankpay['lastName'].errors['minlength']) {
            <div>Last Name must be atleast 3 characters</div>
            }
          </div>
          }
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
        <div class="col-span-3 sm:col-span-1">
          <label for="cardnumnber"
            >Bank Code<span style="color: red">*</span></label
          >
          <input
            class="form-control checking-field"
            id="banknumber"
            placeholder="Bank Code"
            name="banknumber"
            autocomplete="off"
            formControlName="banknumber"
            maxlength="3"
            (input)="touchedmsg()"
            (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
          />

          @if (bankpay['banknumber'].errors && bankpay['banknumber'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['banknumber'].errors['required']) {
            <div>{{ lang.Please_enter_bank_code }}</div>
            } @if (bankpay['banknumber'].errors['checkbanknumbervalidator']) {
            <div>{{ lang.Bank_code_must_be_3_digits }}</div>
            }
          </div>
          }
        </div>
        <!-- style="max-width:120px;" -->
        <div class="col-span-3 sm:col-span-1">
          <label for="transitnumber"
            >{{ lang.Transit }}<span style="color: red">*</span></label
          >
          <!-- (input)="credit_card_number($event)" -->
          <input
            class="form-control checking-field"
            id="branch"
            placeholder="{{ lang.Transit }}"
            name="branch"
            formControlName="transitnumber"
            autocomplete="off"
            maxlength="5"
            (input)="touchedmsg()"
            (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
          />
          @if (bankpay['transitnumber'].errors &&
          bankpay['transitnumber'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['transitnumber'].errors['required']) {
            <div>{{ lang.Please_enter_bank_transit }}</div>
            } @if
            (bankpay['transitnumber'].errors['checktransitnumbervalidator']) {
            <div>{{ lang.Bank_transit_must_be_5_digits }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-3 sm:col-span-1">
          <label for="accountNumber"
            >{{ lang.Account_Number }}<span style="color: red">*</span></label
          >
          <!-- (input)="credit_card_number($event)" -->
          <input
            class="form-control checking-field"
            id="accountnumber"
            placeholder="{{ lang.Account_Number }}"
            autocomplete="off"
            name="accountnumber"
            formControlName="accountnumber"
            maxlength="12"
            (input)="touchedmsg()"
            (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
          />
          @if (bankpay['accountnumber'].errors &&
          bankpay['accountnumber'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['accountnumber'].errors['required']) {
            <div>{{ lang.Please_enter_Account_number }}</div>
            } @if
            (bankpay['accountnumber'].errors['checkaccountnumbervalidator']) {
            <div>{{ lang.Account_Number_must_be_5_12_digits }}</div>
            }
          </div>
          }
        </div>
        @if(bankerrormsg){
        <div class="col-span-3 invalid text-red-500">{{ bankerrormsg }}</div>
        }

        <div class="col-span-3">
          <label for="bankdetails">{{ lang.Bank_Details }}</label>
          <!-- (input)="credit_card_number($event)" -->
          <textarea
            class="form-control"
            id="bankname"
            placeholder="{{ lang.Bank_Details }}"
            name="bankname"
            formControlName="bankname"
            style="color: rgba(85, 85, 85, 0.9)"
            [ngModel]="banknameres"
            rows="6"
          ></textarea>
          @if (bankpay['bankname'].errors && bankpay['bankname'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['bankname'].errors['required']) {
            <div>{{ lang.Please_verify_the_bank_details }}</div>
            }
          </div>
          }
        </div>

        <div class="col-span-3">
          @if(verifybankdetails && verifybutton){
          <button
            type="submit"
            class="w-36 h-14 mb-1 rounded-full border-[1px] border-indigo-200 bg-indigo-200 p-0.5 transition duration-700 ease-in-out hover:bg-white hover:border-green-500"
            (click)="verifybank()"
          >
            {{ lang.Verify }}</button
          >&nbsp; } @if(verifybankdetails){

          <button
            type="submit"
            class="w-36 h-14 mb-1 rounded-full border-[1px] border-indigo-200 bg-gray-200 p-0.5 transition duration-700 ease-in-out hover:bg-white"
            (click)="checkbankdetails($event)"
          >
            {{ lang.Reset }}
          </button>
          }
        </div>

        <!-- Address of customer -->
        <div class="col-span-3 sm:col-span-1">
          <label for="maidenname"
            >{{ lang.Street_Address }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="Address1"
            placeholder="{{ lang.Street_Address }}"
            formControlName="streetaddress"
            autocomplete="off"
          />
          <input type="hidden" name="Address1" />
          @if (bankpay['streetaddress'].errors &&
          bankpay['streetaddress'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['streetaddress'].errors['required']) {
            <div>{{ lang.Please_enter_Street_Address }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-3 sm:col-span-1">
          <label for="maidenname"
            >{{ lang.Street_Address_Line_2 }}<span></span
          ></label>
          <input
            type="text"
            class="form-control"
            id="Address2"
            formControlName="streetaddressline2"
            autocomplete="off"
            placeholder="{{ lang.Street_Address_Line_2 }}"
          />
          <input type="hidden" name="Address2" />
        </div>
        <div class="col-span-3 sm:col-span-1">
          <label for="Apt">Apt<span></span></label>
          <input
            type="text"
            class="form-control"
            id="Apt"
            formControlName="apt"
            autocomplete="off"
            placeholder="Apt"
          />
        </div>
        <div class="col-span-3 sm:col-span-1">
          <label for="maidenname"
            >Primary Phone<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="Address1"
            placeholder="Primary Phone"
            formControlName="primaryPhone"
            autocomplete="off"
          />
          @if (bankpay['primaryPhone'].errors &&
          bankpay['primaryPhone'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['primaryPhone'].errors['required']) {
            <div>Please enter Primary Phone</div>
            }
          </div>
          }
        </div>
        <div class="col-span-3 sm:col-span-1">
          <label for="maidenname"
            >Primary Email<span style="color: red">*</span></label
          >
          <input
            type="email"
            id="email"
            class="form-control checking-field"
            id="Address1"
            placeholder="Primary Email"
            formControlName="primaryEmail"
            autocomplete="off"
          />
          @if (bankpay['primaryEmail'].errors &&
          bankpay['primaryEmail'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['primaryEmail'].errors['required']) {
            <div>Please enter Primary Email</div>
            }
          </div>
          }
        </div>

        <!-- country -->
        <div class="col-span-3 sm:col-span-1">
          <label for="Province">Country<span style="color: red">*</span></label>

          <select
            class="form-control"
            id="StateId"
            placeholder="select"
            formControlName="country"
            (change)="changeCountry($event)"
            autocomplete="off"
          >
            <option value="">{{ lang.Select }}</option>
            <option *ngFor="let name of countriesList" [value]="name.name">
              {{ name.name }}
            </option>
          </select>

          @if (bankpay['country'].errors && bankpay['country'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['country'].errors['country']) {
            <div>Please select Country</div>
            }
          </div>
          }
        </div>

        <div class="col-span-3 sm:col-span-1">
          <label for="maidenname"
            >{{ lang.City }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="City"
            placeholder="{{ lang.City }}"
            formControlName="city"
          />
          <input type="hidden" name="City" />
          @if (bankpay['city'].errors && bankpay['city'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['city'].errors['required']) {
            <div>{{ lang.Please_enter_City }}</div>
            }
          </div>
          }
        </div>
        <!-- <div class="col-span-3 sm:col-span-1">
        <label for="Province">{{ lang.Province }}<span style="color: red">*</span></label>

        <select class="form-control" id="StateId" placeholder="select" formControlName="province"
          (change)="provincelistCreditPayment($event)" autocomplete="off">
          <option value="">{{ lang.Select }}</option>
          <option *ngFor="let name of configprovinceres" [value]="name.shortName">
            {{ name.shortName }}
          </option>
        </select>
        @if (bankpay['province'].errors && bankpay['province'].touched) {
        <div class="invalid text-red-500">
          @if (bankpay['province'].errors['required']) {
          <div>{{ lang.Please_select_Province }}</div>
          }
        </div>
        }
      </div>
      <div class="col-span-3 sm:col-span-1">
        <label for="PostalZip">{{ lang.Postal_Code }}<span style="color: red">*</span></label>
        <input type="text" class="form-control checking-field" id="PostalZip" formControlName="postalcode"
          autocomplete="off" placeholder={{lang.Postal_Code}}
          (input)="changeTextToUppercaseCreditPayment('postalcode',$event)" />
        @if (bankpay['postalcode'].errors && bankpay['postalcode'].touched) {
        <div class="invalid text-red-500">
          @if (bankpay['postalcode'].errors['required']) {
          <div>{{ lang.Please_enter_postal_code }}</div>
          }
        </div>
        } @if (creditCradInvalidProvince==true) {
        <div class="invalid text-red-500">
          {{ lang.Please_enter_valid_Postal_Code }}
        </div>
        }
      </div> -->
        <div class="col-span-3 sm:col-span-1">
          <label for="Province"
            >{{ lang.Province }}<span style="color: red">*</span></label
          >

          <select
            class="form-control"
            id="StateId"
            placeholder="select"
            formControlName="province"
            (change)="provincelistCreditPayment($event)"
            autocomplete="off"
          >
            <option value="">{{ lang.Select }}</option>
            <option
              *ngFor="let name of configprovinceres"
              [value]="name.shortName"
            >
              {{ name.shortName }}
            </option>
          </select>

          @if (bankpay['province'].errors && bankpay['province'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['province'].errors['required']) {
            <div>{{ lang.Please_select_Province }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-3 sm:col-span-1">
          <label for="PostalZip"
            >{{ lang.Postal_Code }}<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control checking-field"
            id="PostalZip"
            formControlName="postalcode"
            autocomplete="off"
            placeholder="{{ lang.Postal_Code }}"
            (input)="changeTextToUppercaseCreditPayment('postalcode', $event)"
          />

          @if (bankpay['postalcode'].errors && bankpay['postalcode'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['postalcode'].errors['required']) {
            <div>{{ lang.Please_enter_postal_code }}</div>
            }
          </div>
          } @if (creditCradInvalidProvince==true) {
          <div class="invalid text-red-500">
            {{ lang.Please_enter_valid_postal_code }}
          </div>
          }
        </div>

        <div class="col-span-2">
          <label for="uploadvoidCheque"
            >{{ lang.Upload_Void_Cheque
            }}<span style="color: red">*</span></label
          >
          <input
            type="file"
            formControlName="voidcheck"
            (change)="voidcheckupload($event)"
          />

          @if (bankpay['voidcheck'].errors && bankpay['voidcheck'].touched) {
          <div class="invalid text-red-500">
            @if (bankpay['voidcheck'].errors['required']) {
            <div>{{ lang.Please_Upload_Void_Cheque_file }}</div>
            }
          </div>
          }
        </div>
        <div class="col-span-1">
          @if(imagedisplay){
          <div class="">
            <button
              type="button"
              class="w-36 h-14 mb-2 mt-2 rounded-full border-2 border-indigo-200 bg-gray-200 p-0.5 hover:border-indigo-700 hover:bg-white"
              (click)="showvoidcheckpreview()"
            >
              {{ lang.Preview }}
            </button>
          </div>
          }
        </div>

        <div class="col-span-3">
          <button
            type="submit"
            class="w-full sm:w-1/2 h-14 mb-1 max-2 rounded-full border-[1px] border-indigo-200 bg-indigo-200 p-0.5 transition duration-700 ease-in-out text-black mt-2"
            (click)="showpadaggrement()"
          >
            {{ lang.Review_and_Sign_the_PAD_Agreement }}
          </button>
          <span class="invalid text-red-500">{{ padaggrementerror }}</span>
        </div>
      </div>
    </form>
    }
    <div class="flex justify-end mt-3">
      @if(paymentForm.get('paymentRadioCheck')?.value === 'CC'){

      <button
        class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5 mx-12 hover:bg-white"
        (click)="creditcardpaysubmit(paymentForm.value, paymentform2)"
      >
        {{ lang.Submit }}
      </button>
      } @if(paymentForm.get('paymentRadioCheck')?.value === 'PAD'){

      <button
        class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5 mx-12 hover:bg-white"
        (click)="bankpayformsubmit(bankpayForm.value)"
      >
        {{ lang.Submit }}
      </button>
      }
    </div>
  </form>
</div>

@if(imageDisplayShow){
<div
  class="relative z-10"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"
  ></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div
      class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div
        class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-1/4 sm:max-w-7xl max-w-2xl"
      >
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white"
            >
              {{ lang.Preview }}
            </div>
            <div class="text-center">
              <div
                class="signature-container col-span-1 w-full"
                style="border: 1px solid #ccc; border-radius: 4px"
              >
                @if(normalview){
                <img [src]="imagedisplay" />
                } @if(pdfview){

                <pdf-viewer
                  [src]="imagedisplay"
                  style="height: 60vh; z-index: 9999; overflow-y: scroll"
                  class="px-0 w-fill object-cover"
                ></pdf-viewer>

                }
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700"
          >
            <button
              type="button"
              class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-gray-200 p-0.5"
              (click)="closeImagePreview()"
            >
              {{ lang.Close }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
} @if(padAggrementModel){
<div
  class="relative z-10"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"
  ></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div
      class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div
        class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-w-2xl"
      >
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white"
            >
              {{ lang.PAD_Agreement }}
            </div>
            <div class="text-center">
              <pdf-viewer
                [src]="generatePADaggrement"
                style="height: 60vh; z-index: 9999; overflow-y: scroll"
                class="px-0 w-fill object-cover"
              ></pdf-viewer>
            </div>
          </div>
        </div>
        <div
          class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700 gap-2"
        >
          @if(signaturestatus){
          <button
            type="button"
            class="w-44 h-14 mb-1 rounded-full order-first border-2 border-green-200 bg-indigo-200 p-0.5 hover:bg-white"
            (click)="signaturestartmodel()"
          >
            {{ lang.Sign_Agree }}
          </button>
          } @if(agreestatus){
          <button
            type="button"
            class="w-36 h-14 mb-1 order-2 rounded-full border-2 border-green-200 bg-indigo-200 p-0.5 hover:bg-white"
            (click)="agreepadconditions()"
          >
            {{ lang.Agree }}
          </button>
          } @if(rejectPADModel){
          <button
            type="button"
            class="w-36 h-14 mb-1 order-3 rounded-full border-2 border-gray-200 bg-indigo-200 p-0.5 hover:bg-white"
            (click)="rejectPADpafaggrement()"
          >
            {{ lang.Reject }}
          </button>
          }

          <!-- <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Deactivate</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
} @if(signModelForBankPay){
<div
  class="relative z-10"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"
  ></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div
      class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div
        class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-1/4 sm:max-w-7xl max-w-2xl"
      >
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white"
            >
              {{ lang.Signature }}
            </div>
            <div class="text-center">
              <div
                class="signature-container col-span-1 w-full"
                style="border: 1px solid #ccc; border-radius: 4px"
              >
                <signature-pad
                  [options]="signaturePadOptions"
                  (touchmove)="onMouseMove($event)"
                  (mousemove)="onMouseMove($event)"
                  (onEndEvent)="drawComplete()"
                  (onBeginEvent)="drawStart()"
                ></signature-pad>
                @if(signaturecheckvalue1cc){
                <div
                  style="
                    left: 10px;
                    color: red;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                >
                  <p (click)="clearSignature()">x {{ lang.Clear }}</p>
                </div>
                }
              </div>
              @if(showpaderrorMessage){
              <span class="invalid text-red-500">{{
                lang.Please_Sign_the_Signature
              }}</span>
              }
            </div>
          </div>
        </div>
        <div
          class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700"
        >
          <button
            type="button"
            class="w-36 h-14 mb-1 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5 hover:bg-white"
            (click)="savePadsignature()"
          >
            {{ lang.Confirm }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
} @if(ShowModelPopup && languageData){
<app-modelpopupconfirmation
  [show]="ShowModelPopup"
  [languageData]="languageData"
  [showHeaderMessage]="ShowModelPopupTitle"
  [showModelContent]="ShowModelPopupMessage"
  (closedModelpopup)="onClosedModelpopup($event)"
>
</app-modelpopupconfirmation>
} @if(padAggrementFinalModel){
<div
  class="relative z-10"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"
  ></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div
      class="flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div
        class="relative transform overflow-hidden rounded-xl bg-white text-center mx-auto shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl max-w-2xl"
      >
        <div class="bg-white">
          <div class="">
            <div
              class="flex items-center justify-center font-bold text-2xl h-16 border border-b-indigo-700 bg-[#00297A] text-white"
            >
              {{ lang.PAD_Agreement }}
            </div>
            <div class="text-center">
              <pdf-viewer
                [src]="generatePADaggrement"
                style="height: 60vh; z-index: 9999; overflow-y: scroll"
                class="px-0 w-fill object-cover"
              ></pdf-viewer>
            </div>
          </div>
        </div>
        <div
          class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border border-t-indigo-700 gap-2"
        >
          @if(showagree){
          <button
            type="button"
            class="w-36 h-14 mb-1 order-2 rounded-full border-2 border-green-200 bg-indigo-200 p-0.5 hover:bg-white"
            (click)="agreepadconditions()"
          >
            {{ lang.Agree }}
          </button>
          } @if(rejectPADModel){
          <button
            type="button"
            class="w-36 h-14 mb-1 order-3 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5 hover:bg-white"
            (click)="rejectPADpafaggrement1()"
          >
            {{ lang.Reject }}
          </button>
          } @if(closePADModel){
          <button
            type="button"
            class="w-36 h-14 mb-1 order-4 rounded-full border-2 border-indigo-200 bg-indigo-200 p-0.5 hover:bg-white"
            (click)="closePADAdggrementModel()"
          >
            {{ lang.Close }}
          </button>
          }
        </div>
      </div>
    </div>
  </div>
</div>
}
