import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  brokerlogo = sessionStorage.getItem("brokerlogo");
  message = sessionStorage.getItem("formName");
  parentBrokerLogo=sessionStorage.getItem("parentBrokerLogo");
  showlang:any;
  updatePaymentRoute: boolean= false;
  ngOnInit(): void {
    this.showlang =sessionStorage.getItem("language")
    this.brokerlogo = sessionStorage.getItem("brokerlogo");
  this.message = sessionStorage.getItem("formName");
  this.parentBrokerLogo = sessionStorage.getItem("parentBrokerLogo");
  console.log(window.location.href,'location')
  if(window.location.href.includes('/update?')){
    this.updatePaymentRoute = true
  }
    setTimeout(() => {
      this.brokerlogo = sessionStorage.getItem("brokerlogo");
      this.message = sessionStorage.getItem("formName");
      this.parentBrokerLogo = sessionStorage.getItem("parentBrokerLogo");


    }, 3000);



    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }
  languageSelect(event:any){
    // console.log(event)
    sessionStorage.setItem("language",event.target.value)
    window.location.reload()

  }
}
