

<!-- @if(ShowModelPopup){
  <app-modelpopupconfirmation [show]="ShowModelPopup" [showHeaderMessage]="ShowModelPopupTitle" [showModelContent]="ShowModelPopupMessage" (closedModelpopup)="onClosedModelpopup($event)">

  </app-modelpopupconfirmation>
  } -->
<app-header *ngIf="!isWildcardRoute"></app-header>
<div class="splash-screen">
  <!-- <h1>Invalid URL</h1> -->
  <h1>{{ShowModelPopupMessage}}</h1>
  <!-- <a routerLink="/">Go back to Home</a> -->
</div>
<app-footer *ngIf="!isWildcardRoute"></app-footer>






