import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CorporateService } from './services/corporate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(public _router: Router, private route: ActivatedRoute,private _CorporateService: CorporateService,){

  }
  title = 'gb';
  ngOnInit(): void {
//   if (this.route.snapshot.params['formLink'] != undefined) {
//       this._CorporateService.getCorporateData(this.route.snapshot.params['formLink'], this.route.snapshot.params['employeeLink']).subscribe({
//         next: (result: any) => {
//           // //console.log(result);

//           if (result.status == "200") {



//           }
//           else {
//             alert("!")

// return false
//           }


//         },
//         error: (error:any) => {
//           if ([0, 500, 501, 502, 503].includes(error.status)) {



//           }
//           else {
//             return false
//           }
//         },
//       });
//     } else {
//     this._router.navigate(["error"]);

//     }

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // sessionStorage.setItem("language","English")
    if(sessionStorage.getItem("language")){

    }
    else{
      sessionStorage.setItem("language","English")
    }
}

  }
