import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrl: './pagenotfound.component.scss'
})
export class PagenotfoundComponent {
  ShowModelPopup: boolean = false;
  ShowModelPopupTitle: any;
  ShowModelPopupMessage: any;
  isWildcardRoute: boolean = false;
  constructor(public router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(() => {
      this.isWildcardRoute = this.router.url === '/';
      console.log(this.isWildcardRoute)
      // Replace '/invalid-url' with your actual wildcard route path if applicable.
    });
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //corporate
    let Message;
    if (sessionStorage.getItem("pagelink") == 'Corporate') {
      Message = 'Corporate'

    }
    else if (sessionStorage.getItem("pageLink") === null) {

      Message = 'Broker';
    } else {
      Message = 'Broker'
    }
    this.ShowModelPopup = true;
    this.ShowModelPopupTitle = 'Info'
    this.ShowModelPopupMessage = `The page you are trying to access is no longer available. Either contact your ${Message} or GroupBenefitz Admin (admin@groupbenefitz.ca)`

  }

  onClosedModelpopup(confirmed: boolean) {
    if (confirmed) {
      this.ShowModelPopup = false;
      this.router.navigate(['']);
    } else {
      this.ShowModelPopup = false;
      this.router.navigate(['']);
    }
  }

}
