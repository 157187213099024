import { environment } from './../environments/environment';

export const StaticApis = {
  personalinfo:'api/corporate/customer/v3/registration',
  validationdata:'api/corporate/customer/v3/validation',
  configData:'api/corporate/customer/formConfigurations',
  configDataCorporate:'api/corporate/customer/v2/formConfigurations',
  padAggrement:'api/corporate/customer/generatePAD',
  padsignatureaggrement:'/api/corporate/customer/updatePAD',

  termsandconditionsvalue:'api/app/server/terms-conditions-en.html',
  paymentdetails:'https://payments.subscriptionplatform.com/v2/payments/create',
  // paymentdetailsapi:'api/corporate/customer/subscribe/packagesPlans',
  paymentdetailsapi:'api/corporate/customer/subscription',
  getbanknames:'api/corporate/customer/bank/verify',

  bankdetailsregistervalues:'api/corporate/customer/bankDetails',
  bankvoidcheck:'api/customer',

  addresscheck:'api/corporate/customer/address/validateUpdate',
  signatureformat:'api/customers/',

  plansdetails:'api/corporate/customer/',
  customerACH:'api/common/customer/pad',
  updatePayment:'api/common/update/paymentDetails',
  login1:'/api/auth/signin',
  getProjectDetails:'/api/user/tse_table_track_fetch_user/',
  getFileDetails:'/api/user/client_repo/testdata_files/list',
  createproject:'api/user/client_repo/create',
  deleteproject:'',
  getreponames:'/api/user/get_client_repo_excel_names',
  deleteExcelfileData:'api/user/client_repo/testdata_files/delete',


  // auathurl:'http://192.168.1.71:8080/groupBenfitz_NEW_ADMIN/adminws/oauth/token?grant_type=password&username=jack&password=password',
// auathurl:'http://192.168.1.71:8080/groupBenfitz_NEW_ADMIN/oauth/token?grant_type=password&username=jack&password=password',
  auathurl:'http://admintestws.groupBenfitz.com/oauth/token?grant_type=password&username=jack&password=password',

  // auathurl:'http://groupBenfitz.com/adminws/oauth/token?grant_type=password&username=jack&password=password',
  // auathurl:'http://localhost:8081/oauth/token?grant_type=password&username=jack&password=password',
  getmaildata:'admin/getMailBodyMessage',
  requestservices:'adminprovider/provider_add_service_request',
  servicerequeststatus:'adminprovider/admin_accept_reject_provider_service',
  login11:'',
  postmaildata:'admin/updateMailBodyMessage',
  getinsurancedays:'app/get_insurance_config',
  insurancedayspost:'app/insert_insurance_config',
  changeproviderstatusactive:'provider/admin_update_provider_active_status',
  activationdetails:'admin/get_registration_mail_receivers',
  getpaymentdetails:'adminbooking/provider_amount_services',
  uploadexcel:'adminbooking/save_provider_amount_paid',
  getstatecount:'adminprovider/providers_registration_state_count',
  servicecount:'adminbooking/top_booking_services',
  serviceprovidersubcount:'admin/year_wise_provider_subscriber_registrations',
  getprovidercount:'adminprovider/providers_position_count',
  createservice:'service/insert_service',
  login:'provider/provider_check_login',

  passwordchange:'admin/admin_update_password',
  forgotpasswords:'admin/admin_forgot_password',
  displyservice:'service/get_services',
  displybookingdetails:'adminbooking/user_bookings',
  getallservicebookings:'admin/get_all_bookings',
  getallservicebookingspayments:'admin/get_all_bookings_payments',
  getallservicebookingstypes:'admin/get_bookings_by_types',
  createpromotion:'promocode/insert_promotion',
  statusofproviders:'admin/get_status_of_providers',
  getserviceproviderslist:'admin/get_booking_notified_providers',
  getserviceproviderdetails:'admin/get_notified_providers_for_bookings',
  getstatusbooking:'admin/get_expired_cancelled_bookings',
  dispalypromotion:'promocode/get_admin_promocodes',
  createmessage:'message/create_message',
  diaplayallmessages:'message/get_messages',
  getallnotifications:'booking/get_all_bookings',
   subscribernotifications:'fcmdevice/get_subscriber_notifications',
  Providernotifications:'fcmdevice/get_provider_notifications',
  messagesearch:'message/search_message',
  displayusermessages:'message/get_user_messages',
  displayadminmessages:'message/get_admin_messages',
  searchmessage:'message/search_message',
  getpositions:'positions/get_positons_list',
  checkservice:'service/check_service_name',
  customersupport:'message/get_subscriber_comment',
  deleteservice:'service/delete_service',
  getdeleteservicedata:'service/get_services_by_id',
  getstates:'zipcode/get_states_list',
  getmedia:'media/get_service_media',
  getcities:'zipcode/get_cities_list',
  deleteprovider:'provider/delete_provider_details',
  subscriberinfo:'subscriber/get_all_subscribers_info?type=verified',
  loginApiEndPoint: '/login',
  publsihservicedata:'/service/publish_service',
  getUsersApiEndPoint: '/getUsers',
  addUserApiEndPoint: '/addUser',
  getsubcat:'positions/get_position_sub_catagories',
  createprovidedetails:'provider/provider_by_id/',
  changeproviderstatusdata:'provider/admin_update_provider_status',
  updateUserApiEndPoint: '/updateUserAdvanced',
  deleteUserApiEndPoint: '/deleteUser',
  getTenantsApiEndPoint: '/getTenants',
  getTenantDetailsApiEndPoint: '/getTenantDetails',
  addTenantApiEndPoint: '/addTenant',
  updateTenantApiEndPoint: '/updateTenant',
  deleteTenantApiEndPoint: '/deleteTenant',
  getnotifications:'fcmdevice/get_notification_by_booking_code',
  providercreation:'positions/add_position',
  servicesearch:'service/get_primary_tag',
  primaryserach:'service/get_primary_dropdown',
  verifyservice:'service/get_service_by_tags',
  cancelrules:'admin/cancel/add_cancelation_rules',
  paymentid:'admin/admin_stripe_tip_percent',
  postpaymentdetails:'admin/admin_stripe_tip_percent_update',

  supportdetailsdata:'app/check_config_info',
  getsupportdetailsdata:'/app/get_app_rating_comment',



  updatesupportdetailsdata:'app/update_app_rating_comment',

  getproviderlinksdetails:'admin/generate_registration_link',




  supporteditdetailsdata:'app/update_config_info',
  subscriberpaymentdetails:'adminsubscriber/subscriber_payments',
  providerpaymentdetails:'adminprovider/provider_payments',
  providerlogin:'provider/provider_check_login',
deletesubcategory:'positions/delete_subcategory',
  forgotpassword: '/forgotPassword',
  resetPassword: '/resetPassword',
  data:'provider/get_all_providers_info?type=',
  getAlerts: '/getAlerts',
  getZonesApiEndPoint: '/getZones',
  getModelsApiEndPoint: '/getModels',
  addZoneApiEndPoint: '/addZone',
  deleteZoneApiEndPoint: '/deleteZone',
  updateZoneApiEndpoint: '/updateZone',
  assignUserToZoneApiEndPoint: '/assignUserToZone',
  assignAssetToZoneApiEndPoint: '/assignAssetToZone',
  unassignUserFromZoneApiEndPoint: '/unassignUserFromZone',
  unAssignAssetFromZoneApiEndPoint: '/unAssignAssetFromZone',
  getAssetsApiEndPoint: '/getAssets',
  deleteAssetsApiEndPoint: '/deleteAsset',
  getHealthStateDataApiEndpoint: '/apps/' + environment.app.id + '/dashboard/asset/healthState?tenantID=',
  getBatteryStatusServiceApiEndpoint: '/apps/' + environment.app.id + '/dashboard/assetsWithAlerts/count?tenantID=',
  getBatteryStatusListServiceApiEndPoint: '/apps/' + environment.app.id + '/dashboard/assetsWithAlerts/list?tenantID=',
  getMonitoredBatteryServiceApiEndPoint: '/apps/' + environment.app.id + '/dashboard/monitoredbatteries/count?tenantID=',
  getChargeStateServiceApiEndPoint: '/apps/' + environment.app.id + '/dashboard/charge/status?tenantID=',
  getHealthStateServiceApiEndPoint: '/apps/' + environment.app.id + '/dashboard/assets/status?tenantID=',
  getStatusApiEndPoint: '/apps/' + environment.app.id + '/dashboard/assets/status?tenantID=',
  getIssuesApiEndPoint: '/apps/' + environment.app.id + 'dashboard/sensors/issues?tenantID=',
  getWeakBatteryCellCountApiEndPoint: '/apps/' + environment.app.id + '/dashboard/assetsWithAlerts/count?tenantID=',
  getTheftBatteryCellCountApiEndPoint: '/apps/' + environment.app.id + '/dashboard/assetsWithAlerts/count?tenantID=',
  getWeakBatteryCellsApiEndPoint: '/apps/' + environment.app.id + '/dashboard/assetsWithWeekCells/list?tenantID=',
  getTheftBatteryBanksApiEndPoint: '/apps/' + environment.app.id + '/dashboard/assetsWithMovementAlerts/list?tenantID=',
  getHealthStateDataApiEndPoint: '/apps/' + environment.app.id + '/dashboard/asset/healthState?tenantID=',
  isValidTokenApiEndpoint: '/deployAndUpdateRules',
  deployAndUpdateRulesApiEndpoint: '/authenticateUserWithToken',



  getCellReadingsApiEndpoint: '/getCellReadings',
  getTrendChartApiEndPoint: '/trendChart',
  getSensorReadingsApiEndPoint: '/getSensorReadings',
  getFaultyCellsApiEndPoint: '/getFaultyCells',
  getAssetInfoApiEndPoint: '/getAssetInfo',
  getDischargeCyclesApiEndPoint: '/getDischargeCycles',
  getChargeCyclesApiEndPoint: '/getChargeCycles',
  getTemperatureApiEndpoint: '/apps/' + environment.app.id + '/analytics/temperature?tenantID=',
  getAhoutInfoApiEndpoint: '/apps/' + environment.app.id + '/analytics/cumulativeAh?tenantID=',
  getVoltagesApiEndpoint: '/apps/' + environment.app.id + '/analytics/voltages?tenantID=',
  getcharge_dischargeVoltageApiEndpoint: '/apps/' + environment.app.id + '/cycle/voltages?tenantID=',
  getCharge_dischargeAHApiEndpoint: '/apps/' + environment.app.id + '/cycle/cumulativeAh?tenantID=',

  energyUtilizationInfoApiEndpoint:'/apps/' + environment.app.id + '/analytics/energyUtilization?tenantID=',
  fuelConsumptionInfoApiEndpoint:'/apps/' + environment.app.id + '/analytics/fuelConsumption?tenantID=',
  fuelTopUpInfoApiEndpoint:'/apps/' + environment.app.id + '/analytics/fuelTopUp?tenantID=',
  siteUpInfoApiEndpoint:'/apps/' + environment.app.id + '/analytics/siteupTime?tenantID=',

  getAssetsServiceApiEndPoint: '/apps/' + environment.app.id + '/analytics/list/geo/assets?tenantID='
};



