import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormGroup, FormControl, Validators, FormBuilder, FormsModule, AbstractControl, ValidationErrors } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CustomDatePipe } from './../pipes/customdate.pipe';

@Component({
  selector: 'app-forms-model',
  templateUrl: './forms-model.component.html',
  styleUrl: './forms-model.component.scss'
})
export class FormsModelComponent {
  myDateValue: Date | undefined;
  Object = Object;
  date: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  // bsConfig: any;

  minDate: Date = new Date();
  maxDate: Date = new Date();
  @Input() show = false;
  @Input() formConfigData: any
  @Input() editChildData: any
  @Input() buttonInfo:any
  @Input() languageData:any;
  @Output() submitForm = new EventEmitter<any>();
  @Output() close = new EventEmitter<void>();
  public languageTokensArray:any =[];
  public lang: any = {};
  selectedLanguage: any;

  childForm = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    gender: new FormControl(),
    date_of_birth: new FormControl(),
    is_child_having_healthcard: new FormControl(),
    child_carrier_name: new FormControl(),
    isDisabled: new FormControl(),
    enrolledInUniversity: new FormControl(),
    graduationDay: new FormControl()

  })
  constructor(private formBuilder: FormBuilder,private _CustomDatePipe:CustomDatePipe) {
    this.childForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      gender: ["", Validators.required],
      date_of_birth: ['', Validators.compose([Validators.required, childrenminimumAgeValidator,this.validateDateforGraduationandBirth])],
      is_child_having_healthcard: ["", Validators.required],
      child_carrier_name: [""],
      isDisabled: [""],
      enrolledInUniversity: [""],
      graduationDay: [""],

    });
    this.minDate = new Date();
    const today = new Date();
    this.minDate.setFullYear(today.getFullYear()-21);
    this.minDate.setMonth(0); // January
    this.minDate.setDate(1); // 1st

    // Set maxDate to December 31st, 2030
    // const today = new Date();
    // let today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    this.maxDate = new Date();
    this.maxDate.setFullYear(today.getFullYear());
    this.maxDate.setMonth(new Date().getMonth()); // December
    this.maxDate.setDate(new Date().getDate()); // 31st


    this.bsConfig = {
      containerClass: 'theme-default',
      dateInputFormat: 'MM-DD-YYYY',
      minDate: this.minDate,
      maxDate: this.maxDate
    };
  }


  get f(): { [key: string]: AbstractControl } {
    return this.childForm.controls;
  }

  ngOnInit(): void {
    this.lang='en'
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.languageTokensArray  =  this.formConfigData


    this.childForm.patchValue(this.editChildData)
     console.log(this.editChildData)
     if(this.editChildData.enrolledInUniversity==true){
      this.childForm.get('graduationDay')?.setValidators(Validators.compose([Validators.required, this.validateDateforGraduation]));

     }

     if (this.editChildData.is_child_having_healthcard == "true") {
      this.childForm.get('child_carrier_name')?.setValidators([Validators.required]);
      this.childForm.get('child_carrier_name')?.updateValueAndValidity();

    } else {
      this.childForm.get('child_carrier_name')?.reset();
      this.childForm.get('child_carrier_name')?.clearValidators();
      this.childForm.get('child_carrier_name')?.updateValueAndValidity();
    }
    // this.editChildDataInfo = this.editChildData

    this.lang = {};
    this.languageData =   this.languageData
    this.selectedLanguage = sessionStorage.getItem("language")
    for (let token of this.languageData) {
      this.lang[token.key] = token[this.selectedLanguage];
    }

  }
  onSubmit() {
    console.log("here")
    if (this.childForm.valid) {

      // let data:any = this.childForm.value;
      // data.date_of_birth =this._CustomDatePipe.transform(
      //   this.childForm.value.date_of_birth),
      //   data["dob"] =this._CustomDatePipe.transform(
      //     this.childForm.value.date_of_birth)
      //     data["first_name"] =this.childForm.value.firstName,
      //     data["last_name"] =this.childForm.value.lastName,
      //     data.is_child_having_healthcard=this.childForm.value.is_child_having_healthcard== "true" ? true : false,
      //     data.child_carrier_name=this.childForm.value.child_carrier_name || '',
      //     data.isDisabled =this.childForm.value.isDisabled == "true" ? true : false,
      //     data.enrolledInUniversity =this.childForm.value.enrolledInUniversity == "true" ? true : false,
      //     data.graduationDay =this._CustomDatePipe.transform(
      //           this.childForm.value.graduationDay)

      // let obj={
      //   "first_name":this.childForm.value.firstName,
      //   "firstName":this.childForm.value.firstName,
      //   "last_name":this.childForm.value.lastName,
      //   "lastName":this.childForm.value.lastName,
      //   "date_of_birth":this._CustomDatePipe.transform(
      //     this.childForm.value.date_of_birth),
      //   "dob":this._CustomDatePipe.transform(
      //     this.childForm.value.date_of_birth),
      //   "gender":this.childForm.value.gender,
      //   "is_child_having_healthcard":this.childForm.value.is_child_having_healthcard== "true" ? true : false,
      //   "child_carrier_name":this.childForm.value.child_carrier_name || '',
      //   "isDisabled":this.childForm.value.isDisabled == "true" ? true : false,
      //   "enrolledInUniversity":this.childForm.value.enrolledInUniversity== "true" ? true : false,
      //   "graduationDay":this._CustomDatePipe.transform(
      //     this.childForm.value.graduationDay)
      // }

      this.submitForm.emit(this.childForm.value);
      this.childForm.reset()
      this.childForm.get('child_carrier_name')?.reset();
      this.childForm.get('child_carrier_name')?.clearValidators();
      this.childForm.get('child_carrier_name')?.updateValueAndValidity();
      this.childForm.get('graduationDay')?.reset();
      this.childForm.get('graduationDay')?.clearValidators();
      this.childForm.get('graduationDay')?.updateValueAndValidity();
      this.show = false;

    }
    else{
      console.log(this.childForm.value)
    }

  }

  onClose() {
    this.close.emit();
    this.show = false;
  }
  public enrolledunversity(event: any) {
    //this.consoleMessage("enrolledUnv: " + event.target.checked)
    if (event.target.checked == true) {
      this.childForm.get('graduationDay')?.setValidators(Validators.compose([Validators.required, this.validateDateforGraduation]));
      this.childForm.get('graduationDay')?.updateValueAndValidity();

    } else {
      this.childForm.get('graduationDay')?.reset();
      this.childForm.get('graduationDay')?.clearValidators();
      this.childForm.get('graduationDay')?.updateValueAndValidity();
    }
  }
  public havingchildrenDependentInsurence(event: any) {

    let childrenVal = event.target.value;
    //this.consoleMessage("havingchildrenDependentInsurence: " + childrenVal)
    if (childrenVal == 'true') {
      this.childForm.get('child_carrier_name')?.setValidators([Validators.required]);
      this.childForm.get('child_carrier_name')?.updateValueAndValidity();

    } else {
      this.childForm.get('child_carrier_name')?.reset();
      this.childForm.get('child_carrier_name')?.clearValidators();
      this.childForm.get('child_carrier_name')?.updateValueAndValidity();
    }
  }

  validateDateforGraduation = (control: AbstractControl): { [key: string]: any } | null => {
    if (this.childForm) {
      let dobControl = this.childForm.get('date_of_birth');
      //this.consoleMessage({ dobControl })
      // if (!dobControl.value) {
      //   return { dateOfBirth: true };
      // }
      if (dobControl) {
        let dob = new Date(dobControl.value);
        if (new Date(control.value) < dob) {
          return { validGraduationDate: true };
        }
      }
    }
    return null;
  };
  validateDateforGraduationandBirth = (control: AbstractControl): { [key: string]: any } | null => {
    if (this.childForm) {
      let gradeControl = this.childForm.get('graduationDay');
      //this.consoleMessage({ dobControl })
      // if (!dobControl.value) {
      //   return { dateOfBirth: true };
      // }
      if (gradeControl) {
        let grade = new Date(gradeControl.value);
        if (new Date(control.value) > grade) {
          return { validGraduationDateBirth: true };
        }
      }
    }
    return null;
  };
  alphabatesOnly(event:any) {
    const key = event.key || String.fromCharCode(event.keyCode);
    // Check if the key is an alphabetical character (A-Z or a-z) or a space
    if (/^[a-zA-ZÀ-ÿ ]*$/.test(key)) {
      return true;
    } else {
      return false;
    }
  }
  numbersOnly(event:any) {
    const key = event.key || String.fromCharCode(event.keyCode);
    // Check if the key is an alphabetical character (A-Z or a-z) or a space
    if (/(^[0-9 \-]+$)/.test(key)) {
      return true;
    } else {
      return false;
    }
  }

  public checkChildDateofBirth(event:any){

    if (event.type === 'input') {
      if(event.target.value ==''){
        this.childForm.controls["date_of_birth"].reset();
        this.childForm.controls["date_of_birth"].markAsTouched();
      }
    }else{
    if(event.target.value=="Invalid date"){
      this.childForm.controls["date_of_birth"].reset();
      this.childForm.controls["date_of_birth"].markAsTouched();
    }
    if(event.target.value ==''){
      this.childForm.controls["date_of_birth"].reset();
      this.childForm.controls["date_of_birth"].markAsTouched();
    }
  }
  }
  public checkDateOfgraducation(event:any){

    if (event.type === 'input') {
      if(event.target.value ==''){
        this.childForm.controls["graduationDay"].reset();
        this.childForm.controls["graduationDay"].markAsTouched();
      }
    }else{
    if(event.target.value=="Invalid date"){
      this.childForm.controls["graduationDay"].reset();
      this.childForm.controls["graduationDay"].markAsTouched();
    }
  if(event.target.value ==''){
    this.childForm.controls["graduationDay"].reset();
    this.childForm.controls["graduationDay"].markAsTouched();
    }
  }
  }

}


export function childrenminimumAgeValidator(control: AbstractControl): any {
  const birthDate = new Date(control.value);
  const today = new Date();
  const age = today.getFullYear() - birthDate.getFullYear();

  console.log(age)
  if (age > 21) {
    return { childrenminimumAge: true };
  }
}
// export function childrenminimumAgeValidator(control: AbstractControl): ValidationErrors | null {
//  return (control: any) => {
//     const birthDate = new Date(control.value);

//     // //this.consoleMessage(birthDate)
//     const today = new Date();
//     const age = today.getFullYear() - birthDate.getFullYear();

//     // //this.consoleMessage(age)
//     // //this.consoleMessage(minimumAge)
//   console.log(age)
//     // console.log(minimumAge)
//     if (age == 55) {
//       return { childrenminimumAge: false };
//     }
//     if (age > 21 || age < 0) {
//       return { childrenminimumAge: true };
//     }


//     return null;
//   };
// }
